<template>
	<div class="indicator-table">
		<div class="close" @click="$emit('close')">
			<font-awesome-icon icon="times-circle"></font-awesome-icon>
		</div>
		<div class="table">
			<table>
				<tr>
					<th class="heading">
						Indicator
					</th>
					<th class="heading">
						Variants
					</th>
					<th class="heading">
						Metrics
					</th>
					<th class="heading">
						Definition
					</th>
					<th class="heading">
						Weighting
					</th>
					<th class="heading">
						Comments
					</th>
				</tr>
				<tr>
					<th class="subheading" colspan="6">
						Climate
					</th>
				</tr>
				<tr>
					<td>
						Average temperature
					</td>
					<td>
						<p>
							Annual, winter, spring, summer and autumn
						</p>
					</td>
					<td>
						<p>
							°C change from 1981-2010 mean
						</p>
					</td>
					<td>
						<p>
							Seasonal average temperature
						</p>
					</td>
					<td>
						No weighting
					</td>
					<td>
						<p>
							The smoothing applied in the development of the transient delta
							method means that this is slightly different to the original
							UKCP18 projections
						</p>
					</td>
				</tr>
				<tr>
					<td>
						Minimum temperature
					</td>
					<td>
						<p>
							Annual, winter, spring, summer and autumn
						</p>
					</td>
					<td>
						<p>
							°C change from 1981-2010 mean
						</p>
					</td>
					<td>
						<p>
							Seasonal average minimum temperature
						</p>
					</td>
					<td>
						No weighting
					</td>
					<td>
						<p>
							The smoothing applied in the development of the transient delta
							method means that this is slightly different to the original
							UKCP18 projections. For the probabilistic projections, this is the
							same as the average temperature
						</p>
					</td>
				</tr>
				<tr>
					<td>
						Maximum temperature
					</td>
					<td>
						<p>
							Annual, winter, spring, summer and autumn
						</p>
					</td>
					<td>
						<p>
							°C change from 1981-2010 mean
						</p>
					</td>
					<td>
						<p>
							Seasonal average maximum temperature
						</p>
					</td>
					<td>
						No weighting
					</td>
					<td>
						<p>
							The smoothing applied in the development of the transient delta
							method means that this is slightly different to the original
							UKCP18 projections. For the probabilistic projections, this is the
							same as the average temperature
						</p>
					</td>
				</tr>
				<tr>
					<td>
						Rainfall
					</td>
					<td>
						<p>
							Annual, winter, spring, summer and autumn
						</p>
					</td>
					<td>
						<p>
							% change from 1981-2010 mean
						</p>
					</td>
					<td>
						Seasonal total rainfall
					</td>
					<td>
						No weighting
					</td>
					<td>
						<p>
							The smoothing applied in the development of the transient delta
							method means that this is slightly different to the original
							UKCP18 projections
						</p>
					</td>
				</tr>
				<tr>
					<td>
						Record-breaking weather
					</td>
					<td>
						Hottest day
					</td>
					<td>
						Days/year
					</td>
					<td>
						<p>
							Number of days per year at least as hot as the hottest day (Tmax)
							between 1981 and 2010
						</p>
					</td>
					<td>
						No weighting
					</td>
					<td>
						<p></p>
					</td>
				</tr>
				<tr>
					<td>
						Record-breaking weather
					</td>
					<td>
						Hottest month
					</td>
					<td>
						Months/year
					</td>
					<td>
						<p>
							Number of months per year at least as hot as the hottest month
							(Tmax) between 1981 and 2010
						</p>
					</td>
					<td>
						No weighting
					</td>
					<td>
						<p></p>
					</td>
				</tr>
				<tr>
					<td>
						Record-breaking weather
					</td>
					<td>
						Wettest month
					</td>
					<td>
						Months/year
					</td>
					<td>
						<p>
							Number of months per year at least as wet as the wettest month
							(Tmax) between 1981 and 2010
						</p>
					</td>
					<td>
						No weighting
					</td>
					<td>
						<p></p>
					</td>
				</tr>
				<tr>
					<th class="subheading" colspan="6">
						Temperature extremes
					</th>
				</tr>
				<tr>
					<td>
						Met Office heatwave
					</td>
					<td>
						<p></p>
					</td>
					<td>
						<p>Events/year</p>
						<p>Duration (days)</p>
						<p>% chance</p>
					</td>
					<td>
						<p>
							Occurrence of heatwave following Met Office definition
						</p>
					</td>
					<td>
						<p>
							Weighted by 2011 population
						</p>
					</td>
					<td>
						<p>
							This represents the occurrence of a heatwave at a particular
							place. In practice, declarations are made at the regional scale if
							a heatwave occurs somewhere in the region.
						</p>
					</td>
				</tr>
				<tr>
					<td>
						Amber heat-health alert
					</td>
					<td>
						<p></p>
					</td>
					<td>
						<p>Events/year</p>
						<p>Duration (days)</p>
						<p>% chance</p>
					</td>
					<td>
						<p>
							Occurrence of a public health heat-health alert
						</p>
					</td>
					<td>
						<p>
							Weighted by 2011 population
						</p>
					</td>
					<td>
						<p>
							This represents the occurrence of an alert at a particular place.
							In practice, declarations are made at the regional scale if a
							heatwave occurs somewhere in the region.
						</p>
					</td>
				</tr>
				<tr>
					<td>
						Cold weather alert
					</td>
					<td>
						<p></p>
					</td>
					<td>
						<p>Events/year</p>
						<p>Duration (days)</p>
						<p>% chance</p>
					</td>
					<td>
						<p>
							Occurrence of a public health cold weather alert
						</p>
					</td>
					<td>
						<p>
							Weighted by 2011 population
						</p>
					</td>
					<td>
						<p>
							This represents the occurrence of an alert at a particular place.
							In practice, declarations are made at the regional scale if a
							heatwave occurs somewhere in the region.
						</p>
					</td>
				</tr>
				<tr>
					<td>
						Very hot days
					</td>
					<td>
						<p></p>
					</td>
					<td>
						<p>Days/year</p>
						<p>% chance</p>
					</td>
					<td>
						<p>
							Days with maximum temperature above 35°C
						</p>
					</td>
					<td>
						<p>
							Weighted by 2011 population
						</p>
					</td>
					<td>
						<p></p>
					</td>
				</tr>
				<tr>
					<td>
						Tropical nights
					</td>
					<td>
						<p></p>
					</td>
					<td>
						<p>Days/year</p>
						<p>% chance</p>
					</td>
					<td>
						<p>
							Days with minimum temperature above 20°C
						</p>
					</td>
					<td>
						<p>
							Weighted by 2011 population
						</p>
					</td>
					<td>
						<p></p>
					</td>
				</tr>
				<tr>
					<td>
						Heat stress
					</td>
					<td>
						<p></p>
					</td>
					<td>
						<p>Days/year</p>
						<p>% chance</p>
					</td>
					<td>
						<p>
							Days with shade Wet Bulb Globe Temperature (WBGT) above 25
						</p>
					</td>
					<td>
						<p>
							Weighted by 2011 population
						</p>
					</td>
					<td>
						<p></p>
					</td>
				</tr>
				<tr>
					<th class="subheading" colspan="6">
						Heating and cooling
					</th>
				</tr>
				<tr>
					<td>
						Heating degree days
					</td>
					<td>
						<p>
							Relative to 15.5°C and 18°C
						</p>
					</td>
					<td>
						<p>
							°C-days
						</p>
					</td>
					<td>
						<p>
							Average annual heating degree days
						</p>
					</td>
					<td>
						<p>
							Weighted by 2011 population
						</p>
					</td>
					<td>
						<p></p>
					</td>
				</tr>
				<tr>
					<td>
						Cooling degree days
					</td>
					<td>
						<p>
							Relative to 18°C and 22°C
						</p>
					</td>
					<td>
						<p>
							°C-days
						</p>
					</td>
					<td>
						<p>
							Average annual cooling degree days
						</p>
					</td>
					<td>
						<p>
							Weighted by 2011 population
						</p>
					</td>
					<td>
						<p></p>
					</td>
				</tr>
				<tr>
					<th class="subheading" colspan="6">
						Transport
					</th>
				</tr>
				<tr>
					<td>
						Road melt risk
					</td>
					<td>
						<p></p>
					</td>
					<td>
						Days/year
					</td>
					<td>
						<p>
							Days with Tmax above 25°C
						</p>
					</td>
					<td>
						<p>
							Weighted by length of motorway and A-road
						</p>
					</td>
					<td>
						<p>
							An indicator for melting of asphalt road surfaces
						</p>
					</td>
				</tr>
				<tr>
					<td>
						Road accident risk
					</td>
					<td>
						<p></p>
					</td>
					<td>
						Days/year
					</td>
					<td>
						<p>
							Days with Tmin below 0°C
						</p>
					</td>
					<td>
						<p>
							Weighted by length of motorway and A-road
						</p>
					</td>
					<td>
						<p>
							An indicator of increased road accident risk due to icy conditions
						</p>
					</td>
				</tr>
				<tr>
					<td>
						Rail: high temperatures
					</td>
					<td>
						<p>
							&gt;21, 24, 26, 30°C
						</p>
					</td>
					<td>
						Days/year
					</td>
					<td>
						<p>
							Days with Tmax above thresholds
						</p>
					</td>
					<td>
						<p>
							Weighted by length of rail network
						</p>
					</td>
					<td>
						<p>
							Critical thresholds for increased failure risk for various aspects
							of railway infrastructure
						</p>
					</td>
				</tr>
				<tr>
					<td>
						Rail: bad weather days
					</td>
					<td>
						Adverse weather days Extreme weather days
					</td>
					<td>
						Days/year
					</td>
					<td>
						<p>
							Days with temperature, rainfall and/or windspeed beyond thresholds
						</p>
					</td>
					<td>
						<p>
							Weighted by length of rail network
						</p>
					</td>
					<td>
						<p>
							Standards of service for rail operating companies are relaxed on
							bad weather days.
						</p>
					</td>
				</tr>
				<tr>
					<th class="subheading" colspan="6">
						Agriculture
					</th>
				</tr>
				<tr>
					<td>
						<p>
							Start of crop growing season
						</p>
					</td>
					<td>
						<p></p>
					</td>
					<td>
						<p>
							Day of year (1 = January 1st)
						</p>
					</td>
					<td>
						<p>
							First day of first five-day period with average temperature above
							5.6°C
						</p>
					</td>
					<td>
						<p>
							Weighted by area of cropland and improved pasture
						</p>
					</td>
					<td>
						<p></p>
					</td>
				</tr>
				<tr>
					<td>
						Growing season length
					</td>
					<td>
						<p></p>
					</td>
					<td>
						Days
					</td>
					<td>
						<p>
							Length between start and end of growing season
						</p>
					</td>
					<td>
						<p>
							Weighted by area of cropland and improved pasture
						</p>
					</td>
					<td>
						<p></p>
					</td>
				</tr>
				<tr>
					<td>
						Growing degree days
					</td>
					<td>
						<p></p>
					</td>
					<td>
						<p>
							°C-days
						</p>
					</td>
					<td>
						<p>
							Accumulated degree-days relative to 5.6°C
						</p>
					</td>
					<td>
						<p>
							Weighted by area of cropland and improved pasture
						</p>
					</td>
					<td>
						<p></p>
					</td>
				</tr>
				<tr>
					<td>
						Crop growth duration
					</td>
					<td>
						<p></p>
					</td>
					<td>
						Days
					</td>
					<td>
						<p>
							Time to accumulate the 1981-2010 average growing degree days
						</p>
					</td>
					<td>
						<p>
							Weighted by area of cropland
						</p>
					</td>
					<td>
						<p>
							A proxy for the effect of higher temperature on potential crop
							yield
						</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>
							Start of field operations (Tsum200)
						</p>
					</td>
					<td>
						<p></p>
					</td>
					<td>
						<p>
							Day of year (1 = January 1st)
						</p>
					</td>
					<td>
						<p>
							Day when accumulated temperatures above 0°C reach 200°C
						</p>
					</td>
					<td>
						<p>
							Weighted by area of cropland and improved pasture
						</p>
					</td>
					<td>
						<p></p>
					</td>
				</tr>
				<tr>
					<td>
						Accumulated frost
					</td>
					<td>
						<p></p>
					</td>
					<td>
						<p>
							°C-days
						</p>
					</td>
					<td>
						<p>
							Sum of minimum temperatures below 0°C
						</p>
					</td>
					<td>
						<p>
							Weighted by area of cropland and improved pasture
						</p>
					</td>
					<td>
						<p>
							A proxy for the likelihood of pests surviving winter
						</p>
					</td>
				</tr>
				<tr>
					<td>
						Frost days
					</td>
					<td>
						<p></p>
					</td>
					<td>
						Days/year
					</td>
					<td>
						<p>
							Days with Tmin below 0°C
						</p>
					</td>
					<td>
						<p>
							Weighted by area of cropland and improved pasture
						</p>
					</td>
					<td>
						<p></p>
					</td>
				</tr>
				<tr>
					<td>
						Dairy cattle heat stress
					</td>
					<td>
						<p></p>
					</td>
					<td>
						Days/year
					</td>
					<td>
						<p>
							Days with a Temperature Humidity Index above 70.
						</p>
					</td>
					<td>
						<p>
							Weighted by area of improved pasture
						</p>
					</td>
					<td>
						<p>
							Milk yield falls on heat stress days
						</p>
					</td>
				</tr>
				<tr>
					<td>
						Wheat heat stress
					</td>
					<td>
						<p></p>
					</td>
					<td>
						<p>Days/year</p>
						<p>% chance</p>
					</td>
					<td>
						<p>
							Days between 1 May and 15 June with maximum temperature above 32°C
						</p>
					</td>
					<td>
						<p>
							Weighted by area of cropland
						</p>
					</td>
					<td>
						<p>
							Heat stress during flowering and seed setting period (anthesis)
							reduces yield
						</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>
							Potential Soil Moisture Deficit
						</p>
					</td>
					<td>
						<p></p>
					</td>
					<td>
						mm
					</td>
					<td>
						<p>
							Average annual maximum cumulative difference between rainfall and
							potential evaporation
						</p>
					</td>
					<td>
						<p>
							Weighted by area of cropland and improved pasture
						</p>
					</td>
					<td>
						<p>
							A measure of the need for supplemental irrigation
						</p>
					</td>
				</tr>
				<tr>
					<td>
						Days above field capacity
					</td>
					<td>
						<p></p>
					</td>
					<td>
						Days/year
					</td>
					<td>
						<p>
							Number of days with soil moisture at or above field capacity
						</p>
					</td>
					<td>
						<p>
							Weighted by area of cropland and improved pasture
						</p>
					</td>
					<td>
						<p>
							A measure of field workability
						</p>
					</td>
				</tr>
				<tr>
					<td>
						SPI drought
					</td>
					<td>
						<p></p>
					</td>
					<td>
						Proportion of time
					</td>
					<td>
						<p>
							Number of months with a Standardised Precipitation Index
							(calculated from 3-month accumulated rainfall) less than -1.5.
						</p>
					</td>
					<td>
						<p>
							Weighted by area of cropland and improved pasture
						</p>
					</td>
					<td>
						<p>
							This occurs 0.067 of the time over the period 1981-2010 by
							definition.
						</p>
					</td>
				</tr>
				<tr>
					<td>
						SPEI drought
					</td>
					<td>
						<p></p>
					</td>
					<td>
						Proportion of time
					</td>
					<td>
						<p>
							Number of months with a Standardised Precipitation Evaporation
							Index (calculated from 6-month accumulated rainfall minus
							potential evaporatin) less than -1.5.
						</p>
					</td>
					<td>
						<p>
							Weighted by area of cropland and improved pasture
						</p>
					</td>
					<td>
						<p>
							This occurs 0.067 of the time over the period 1981-2010 by
							definition.
						</p>
					</td>
				</tr>
				<tr>
					<th class="subheading" colspan="6">
						Wildfire
					</th>
				</tr>
				<tr>
					<td>
						<p>Met Office Fire Danger</p>
					</td>
					<td>
						<p>Very High</p>
						<p>Exceptional</p>
					</td>
					<td>
						<p>Days/year</p>
						<p>% chance greater than the current mean</p>
					</td>
					<td>
						<p>
							Days with the MOFSI index above specific thresholds
						</p>
					</td>
					<td>
						<p>
							Weighted by area of heathland, moorland and grassland
						</p>
					</td>
					<td>
						<p>
							Based on an approximation to the Met Office Fire Severity Index
							used to issue wildfire warnings to limit access to open access
							land.
						</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Wildfire: Daily Hazard Assessment</p>
					</td>
					<td>
						<p>Yellow</p>
						<p>Amber</p>
					</td>
					<td>
						<p>Days/year</p>
						<p>% chance greater than the current mean</p>
					</td>
					<td>
						<p>
							Days with MOFSI components above specific thresholds
						</p>
					</td>
					<td>
						<p>
							Weighted by area of heathland, moorland and grassland
						</p>
					</td>
					<td>
						<p>
							Based on an approximation to the Met Office Fire Severity Index,
							with thresholds used in the Natural Hazards Partnership Daily
							Hazard Assessment.
						</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>
							Wildfire: FFMC 99th percentile
						</p>
					</td>
					<td>
						<p></p>
					</td>
					<td>
						<p>Days/year</p>
					</td>
					<td>
						<p>
							Days with Fine Fuel Moisture Code component of MOFSI above the
							99th percentile over the period 1981-2010, calculated by season
						</p>
					</td>
					<td>
						<p>
							Weighted by area of heathland, moorland and grassland
						</p>
					</td>
					<td>
						<p>
							Based on an approximation to the Met Office Fire Severity Index.
							By definition there are on average around 3.65 days per year above
							the threshold in the period 1981-2010.
						</p>
					</td>
				</tr>
				<tr>
					<th class="subheading" colspan="6">
						Water
					</th>
				</tr>
				<tr>
					<td><p>River runoff</p></td>
					<td><p>Annual, winter, spring, summer and autumn</p></td>
					<td><p>% change from 1981-2010 mean</p></td>
					<td><p>Annual and seasonal river runoff</p></td>
					<td><p>No weighting</p></td>
					<td>
						<p>
							Calculated using a hydrological model and averaged to produce
							regional average changes. England, Wales and Scotland only.
						</p>
					</td>
				</tr>
				<tr>
					<td><p>River flood</p></td>
					<td><p>2, 5 and 10-year return period floods</p></td>
					<td><p>% change from 1981-2010 mean</p></td>
					<td><p>Magnitude of the T-year flood</p></td>
					<td><p>No weighting</p></td>
					<td>
						<p>
							This is a measure of change in the size of river floods. England,
							Wales and Scotland only.
						</p>
					</td>
				</tr>
				<tr>
					<td><p>River flood</p></td>
					<td><p>Likelihood of current 10-year event</p></td>
					<td><p>% chance of occurring</p></td>
					<td><p>Likelihood of the T-year flood</p></td>
					<td><p>No weighting</p></td>
					<td>
						<p>
							This is a measure of change in the likelihood of river flooding.
							The current value is 10%. England, Wales and Scotland only.
						</p>
					</td>
				</tr>
				<tr>
					<td><p>Low flows</p></td>
					<td><p>2 and 10-year return periods</p></td>
					<td><p>% change from 1981-2010 mean</p></td>
					<td><p>Magnitude of low river flows</p></td>
					<td><p>No weighting</p></td>
					<td>
						<p>
							This is the change in the T-year lowest 7-day average river flow.
							England, Wales and Scotland only.
						</p>
					</td>
				</tr>
				<tr>
					<td><p>Low flows</p></td>
					<td><p>Likelihood of current 10-year event</p></td>
					<td><p>% chance of occurring</p></td>
					<td><p>Likelihood of low river flows</p></td>
					<td><p>No weighting</p></td>
					<td>
						<p>
							This is the measure of change in the likelihood of experiencing
							low river flows. The current value is 10%. England, Wales and
							Scotland only.
						</p>
					</td>
				</tr>
				<tr>
					<td><p>12-month river drought</p></td>
					<td><p>Extreme, Severe</p></td>
					<td><p>Proportion of time</p></td>
					<td>
						<p>
							Number of months with a Standardised Streamflow Index (calculated
							from 12-month accumulated river flow) less than -1.5 or -2.0.
						</p>
					</td>
					<td><p>No weighting</p></td>
					<td>
						<p>
							The Standardised Streamflow Index is based on the streamflow
							accumulated over a number of months, standardised with a mean of
							zero a value below zero represents below average flows. An extreme
							drought has a value less than -1.5 and a severe drought has a
							value less than -2.0. England, Wales and Scotland only.
						</p>
					</td>
				</tr>
				<tr>
					<td><p>24-month river drought</p></td>
					<td><p>Extreme, Severe</p></td>
					<td><p>Proportion of time</p></td>
					<td>
						<p>
							Number of months with a Standardised Streamflow Index (calculated
							from 24-month accumulated river flow) less than -1.5 or -2.0.
						</p>
					</td>
					<td><p>No weighting</p></td>
					<td>
						<p>
							The Standardised Streamflow Index is based on the streamflow
							accumulated over a number of months, standardised with a mean of
							zero a value below zero represents below average flows. An extreme
							drought has a value less than -1.5 and a severe drought has a
							value less than -2.0. England, Wales and Scotland only.
						</p>
					</td>
				</tr>
				<tr>
					<td><p>Soil moisture</p></td>
					<td><p>Winter, spring, summer and autumn</p></td>
					<td><p>% change from 1981-2010 mean</p></td>
					<td><p>Average seasonal soil moisture content</p></td>
					<td><p>No weighting.</p></td>
					<td><p>England, Wales and Scotland only.</p></td>
				</tr>
			</table>
		</div>
	</div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'

@Component({
	name: 'IndicatorTable'
})
export default class IndicatorTable extends Vue {
	mounted() {}
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/climpolVars.scss';

.indicator-table {
	background-color: $mainBgColor;
	position: relative;

	.close {
		color: $red;
		cursor: pointer;
		position: absolute;
		top: 8px;
		right: 8px;
		width: 24px;
		height: 24px;

		svg {
			width: 100%;
			height: 100%;
		}
	}

	.table {
		position: absolute;
		top: 32px;
		left: 8px;
		width: calc(100% - 16px);
		height: calc(100% - 40px);
		overflow-y: auto;

		table {
			border-spacing: 0;
			tr {
				margin: 0;
				padding: 0;
			}

			th.heading {
				background-color: $dark;
				color: white;
				position: sticky;
				top: 0;
				height: 16px;

				border-top: 2px solid white;
				border-bottom: 2px solid white;
				border-left: 1px solid white;
				border-right: none;
				margin: 0;

				text-align: start;
				padding-left: 8px;
				&:first-child {
					border-left: none;
				}
			}

			th.subheading {
				background-color: $medium;
				color: white;
				position: sticky;
				top: 26px;
				text-align: start;
				padding-left: 8px;
			}

			td {
				border-left: 1px solid $light;
				border-bottom: 1px solid $light;
				border-right: none;
				border-top: none;
				margin: 0;
				text-align: start;
				padding: 4px;

				&:first-child {
					font-weight: bold;
				}

				&:last-child {
					border-right: 1px solid $light;
				}
			}
		}
	}
}
</style>
