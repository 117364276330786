import { ScaleLinear, scaleLinear } from 'd3-scale'
import { GeoJSON } from 'leaflet'
import { MutationTree } from 'vuex'
import * as s from './state'

export enum MutationTypes {
	SET_CATEGORY = 'SET_CATEGORY',
	SET_INDICATOR = 'SET_INDICATOR',
	SET_VARIANT = 'SET_VARIANT',
	SET_METRIC = 'SET_METRIC',
	SET_STRAND = 'SET_STRAND',
	SET_EMISSIONS = 'SET_EMISSIONS',
	SET_MEMBER = 'SET_MEMBER',
	SET_RESOLUTION = 'SET_RESOLUTION',
	SET_SPATIAL_UNIT = 'SET_SPATIAL_UNIT',
	SET_TIME_PERIOD = 'SET_TIME_PERIOD',
	SET_DATA = 'SET_DATA',
	SET_COMPARE_DATA = 'SET_COMPARE_DATA',
	SET_GEODATA = 'SET_GEODATA',
	SET_SPATIAL_NAMES = 'SET_SPATIAL_NAMES',
	TOGGLE_ADDING_REGIONS = 'TOGGLE_ADDING_REGIONS',
	TOGGLE_EXTRA_SPATIAL_UNIT = 'TOGGLE_EXTRA_SPATIAL_UNIT',
	CLEAR_EXTRA_SPATIAL_UNITS = 'CLEAR_EXTRA_SPATIAL_UNITS',
	TOGGLE_ADDING_SCENARIOS = 'TOGGLE_ADDING_SCENARIOS',
	SET_EXTRA_SCENARIO = 'SET_EXTRA_SCENARIO',
	SET_LOADING = 'SET_LOADING',
	SET_FINISHED_LOADING = 'SET_FINISHED_LOADING',
	SET_SHOW_WELCOME = 'SET_SHOW_WELCOME'
}

export type Mutations<S = s.State> = {
	[MutationTypes.SET_CATEGORY](state: S, payload: s.CategoryType): void
	[MutationTypes.SET_INDICATOR](state: S, payload: s.IndicatorType): void
	[MutationTypes.SET_VARIANT](state: S, payload: s.VariantType): void
	[MutationTypes.SET_METRIC](state: S, payload: s.MetricType): void
	[MutationTypes.SET_STRAND](state: S, payload: s.StrandType): void
	[MutationTypes.SET_EMISSIONS](state: S, payload: s.EmissionType): void
	[MutationTypes.SET_MEMBER](state: S, payload: s.MemberType): void
	[MutationTypes.SET_RESOLUTION](state: S, payload: s.ResolutionType): void
	[MutationTypes.SET_SPATIAL_UNIT](state: S, payload: s.SpatialUnitType): void
	[MutationTypes.SET_TIME_PERIOD](state: S, payload: s.TimePeriodType): void
	[MutationTypes.SET_DATA](state: S, payload: any): void
	[MutationTypes.SET_COMPARE_DATA](state: S, payload: any): void
	[MutationTypes.SET_GEODATA](state: S, payload: any): void
	[MutationTypes.SET_SPATIAL_NAMES](state: S, payload: any): void
	[MutationTypes.TOGGLE_ADDING_REGIONS](state: S): void
	[MutationTypes.TOGGLE_EXTRA_SPATIAL_UNIT](
		state: S,
		payload: s.SpatialUnitType
	): void
	[MutationTypes.CLEAR_EXTRA_SPATIAL_UNITS](state: S): void
	[MutationTypes.TOGGLE_ADDING_SCENARIOS](state: S): void
	[MutationTypes.SET_EXTRA_SCENARIO](state: S, payload: s.Scenario | null): void
	[MutationTypes.SET_LOADING](state: S): void
	[MutationTypes.SET_FINISHED_LOADING](state: S): void
	[MutationTypes.SET_SHOW_WELCOME](state: S, payload: boolean): void
}

export const mutations: MutationTree<s.State> & Mutations = {
	[MutationTypes.SET_CATEGORY](state, payload: s.CategoryType) {
		state.category = payload
		// Changing the indicator category will make the current indicator invalid
		const indicatorIds = s.indicatorFilter[state.category]
		if (!indicatorIds.includes(state.indicator)) {
			// Default can be any - pick 1st
			state.indicator = indicatorIds[0]
			// Changing the indicator can make the current variant invalid
			const variantIds = s.variantFilter[state.indicator]
			if (!variantIds.includes(state.variant)) {
				// Default can be any - pick 1st
				state.variant = variantIds[0]
			}
			// Changing the indicator can make the current metric invalid
			const metricIds = s.metricFilter[state.indicator]
			if (!metricIds.includes(state.metric)) {
				// Default can be any - pick 1st
				state.metric = metricIds[0]
			}
			if (s.specialIndicators.includes(state.indicator)) {
				// Some indicators have a limited set of resolutions, emissions,
				// and strands
				if (!s.specialValues.resolutions.includes(state.resolution)) {
					state.resolution = s.specialValues.resolutions[0]
				}

				if (!s.specialValues.emissions.includes(state.emission)) {
					state.emission = s.specialValues.emissions[0]
				}

				if (!s.specialValues.strands.includes(state.strand)) {
					state.strand = s.specialValues.strands[0]
				}
			}
		}
	},
	[MutationTypes.SET_INDICATOR](state, payload: s.IndicatorType) {
		state.indicator = payload
		// Changing the indicator can make the current variant invalid
		const variantIds = s.variantFilter[state.indicator]
		if (!variantIds.includes(state.variant)) {
			// Default can be any - pick 1st
			state.variant = variantIds[0]
		}
		// Changing the indicator can make the current metric invalid
		const metricIds = s.metricFilter[state.indicator]
		if (!metricIds.includes(state.metric)) {
			// Default can be any - pick 1st
			state.metric = metricIds[0]
		}
		if (s.specialIndicators.includes(state.indicator)) {
			// Some indicators have a limited set of resolutions, emissions,
			// and strands
			if (!s.specialValues.resolutions.includes(state.resolution)) {
				state.resolution = s.specialValues.resolutions[0]
			}

			if (!s.specialValues.emissions.includes(state.emission)) {
				state.emission = s.specialValues.emissions[0]
			}

			if (!s.specialValues.strands.includes(state.strand)) {
				state.strand = s.specialValues.strands[0]
			}
		}
	},
	[MutationTypes.SET_VARIANT](state, payload: s.VariantType) {
		// Changing the variant for record-breaking temperature
		// can make the current metric invalid
		if (state.indicator === 'recordtemp') {
			if (payload === 'hotday') {
				state.metric = 'days'
			} else if (payload === 'hotmonth' || payload === 'wetmonth') {
				state.metric = 'months'
			}
		}
		// Changing the variant from y10 can make the current metric invalid
		if (state.variant === 'y10') {
			// 'percent' is always valid for changes from y10
			state.metric = 'percent'
		}
		state.variant = payload
	},
	[MutationTypes.SET_METRIC](state, payload: s.MetricType) {
		state.metric = payload
	},
	[MutationTypes.SET_STRAND](state, payload: s.StrandType) {
		state.strand = payload
		// Changing the strand can make the current member invalid
		const memberIds = s.memberFilter[state.strand]
		if (!memberIds.includes(state.member)) {
			// Default should be median
			state.member = 'median'
		}
	},
	[MutationTypes.SET_EMISSIONS](state, payload: s.EmissionType) {
		// Changing the emission can make the current strand invalid
		const strandIds = s.strandFilter[payload]
		if (!strandIds.includes(state.strand)) {
			// Default can be any - pick 1st
			state.strand = strandIds[0]

			const memberIds = s.memberFilter[state.strand]
			if (!memberIds.includes(state.member)) {
				// Default should be median
				state.member = 'median'
			}
		}
		// Changing the emission to warming can make the current member invalid
		if (payload === 'warming' && state.strand === 'cmip') {
			if (!s.cmipWarmingMembers.includes(state.member)) {
				state.member = 'median'
			}
		}
		// Changing the emission to warming can make the current "time period"
		// (actually a warming level) invalid
		if (payload === 'warming') {
			state.timePeriod = 't8'
		} else if (state.emission === 'warming') {
			// If we are changing FROM warming to something else,
			// select the final time period
			state.timePeriod = 't10'
		}

		state.emission = payload
	},
	[MutationTypes.SET_MEMBER](state, payload: s.MemberType) {
		state.member = payload
	},
	[MutationTypes.SET_RESOLUTION](state, payload: s.ResolutionType) {
		state.resolution = payload
		// Clear extra selected units when changing resolution
		state.additionalSpatialUnits = []
	},
	[MutationTypes.SET_SPATIAL_UNIT](state, payload: s.SpatialUnitType) {
		state.spatialUnit = payload
	},
	[MutationTypes.SET_TIME_PERIOD](state, payload: s.TimePeriodType) {
		state.timePeriod = payload
	},
	[MutationTypes.SET_DATA](state, payload: s.Datum[]) {
		const data: { [key: string]: { [key: number]: s.Datum } } = {}
		// Now set the scales based on the data
		const mins: { [key: number]: number[] } = []
		const maxes: { [key: number]: number[] } = []
		for (let row of payload) {
			const location = row.location.trim()
			if (data[location] === undefined) {
				data[location] = {}
			}
			data[location][row.year] = row

			if (mins[row.year] === undefined) {
				mins[row.year] = []
				maxes[row.year] = []
			}
			if (row.min !== undefined) {
				mins[row.year].push(row.min)
			} else if (row.pc10 !== undefined) {
				mins[row.year].push(row.pc10)
			} else {
				// Should never get here
				mins[row.year].push(row.median)
			}
			if (row.max !== undefined) {
				maxes[row.year].push(row.max)
			} else if (row.pc90 !== undefined) {
				maxes[row.year].push(row.pc90)
			} else {
				// Should never get here
				maxes[row.year].push(row.median)
			}
		}
		const scales: { [key: number]: ScaleLinear<Number, Number> } = {}
		// @ts-ignore
		// Object.keys always returns strings, despite having type of number here
		for (let i of Object.keys(mins) as number[]) {
			if (mins[i] && maxes[i]) {
				if (s.indicators[state.indicator].symmetrical) {
					// We need a symmetrical scale
					const maxAbs = Math.max(
						...mins[i].map(Math.abs),
						...maxes[i].map(Math.abs)
					)
					scales[i] = scaleLinear().domain([-maxAbs, maxAbs])
				} else {
					scales[i] = scaleLinear().domain([
						Math.min(...mins[i]),
						Math.max(...maxes[i])
					])
				}
			}
		}
		state.scales = scales
		state.data = data
	},
	[MutationTypes.SET_COMPARE_DATA](state, payload: s.Datum[]) {
		const data: { [key: string]: { [key: number]: s.Datum } } = {}
		for (let row of payload) {
			const location = row.location.trim()
			if (data[location] === undefined) {
				data[location] = {}
			}
			data[location][row.year] = row
		}
		state.compareData = data
	},
	[MutationTypes.SET_GEODATA](state, payload: GeoJSON) {
		state.geoData = payload
	},
	[MutationTypes.SET_SPATIAL_NAMES](
		state,
		payload: { name: string; val: string }[]
	) {
		payload.sort((a, b) => (a.name < b.name ? -1 : 1))
		state.spatialNames = payload
	},
	[MutationTypes.TOGGLE_ADDING_REGIONS](state) {
		state.addingAdditionalUnits = !state.addingAdditionalUnits
	},
	[MutationTypes.TOGGLE_EXTRA_SPATIAL_UNIT](state, payload: s.SpatialUnitType) {
		if (state.spatialUnit === payload) {
			// Don't add as an extra spatial unit if this is already the main one
			return
		}
		const index = state.additionalSpatialUnits.indexOf(payload)
		if (index > -1) {
			state.additionalSpatialUnits.splice(index, 1)
		} else if (state.additionalSpatialUnits.length < s.maxAdditional) {
			// Only add if we haven't reached the maximum capacity
			state.additionalSpatialUnits.push(payload)
		}
	},
	[MutationTypes.CLEAR_EXTRA_SPATIAL_UNITS](state) {
		state.additionalSpatialUnits = []
	},
	[MutationTypes.TOGGLE_ADDING_SCENARIOS](state) {
		state.addingAdditionalScenarios = !state.addingAdditionalScenarios
	},
	[MutationTypes.SET_EXTRA_SCENARIO](state, payload: s.Scenario | null) {
		if (
			state.emission === payload?.emission &&
			state.strand === payload?.strand
		) {
			// Don't add as an extra scenario if this is already the main one
			return
		}
		if (payload === null) {
			state.compareData = {}
		} else {
			if (!s.strandFilter[payload.emission].includes(payload.strand)) {
				payload.strand = s.strandFilter[payload.emission][0]
			}
		}
		state.additionalScenario = payload
	},
	[MutationTypes.SET_LOADING](state) {
		state.loadingCount++
	},
	[MutationTypes.SET_FINISHED_LOADING](state) {
		state.loadingCount--
	},
	[MutationTypes.SET_SHOW_WELCOME](state, payload: boolean) {
		state.showWelcomePage = payload
	}
}
