<template>
	<div class="map-container">
		<l-map
			ref="map"
			class="map"
			:zoom="5"
			:min-zoom="3"
			:max-bounds="bounds"
			:max-bounds-viscosity="1"
			:center="[54.5, -3]"
		>
			<l-tile-layer
				:url="bgLayer.url"
				:attribution="bgLayer.attribution"
				layer-type="base"
				:zIndex="1"
			></l-tile-layer>
			<l-control class="legend toggle" position="bottomleft">
				<p>Colour scale</p>
				<label for="localOn">
					<input
						type="radio"
						v-model="localMinMax"
						:value="true"
						id="localOn"
					/>
					{{ memberName }}
				</label>
				<label for="localOn">
					<input
						type="radio"
						v-model="localMinMax"
						:value="false"
						id="localOn"
					/>
					All members
				</label>
			</l-control>
			<l-control class="legend color" position="bottomleft">
				<div
					class="colorbar"
					title="Click to toggle between scale for selected member and all members"
					@click="localMinMax = !localMinMax"
				>
					<p>
						{{ localMinMax ? `${memberName}` : 'All members' }} <br />
						{{ metricName }}
					</p>
					<div v-for="i in 20" class="line" :key="i">
						<div
							class="box"
							:style="`background-color: ${colourScale(1 - i / 20)}`"
						></div>
						<p class="label" v-if="(i == 1 || i == 10 || i == 20) && scale">
							{{ getScaleValue(scale, i) }}
						</p>
					</div>
				</div>
			</l-control>
			<l-control v-if="adding" class="legend multiple" position="topright">
				<p>
					Click to add/remove regions. Selected regions will be added to the
					chart below. {{ remaining }} extra regions remaining.
				</p>
			</l-control>
			<l-control class="legend value" position="bottomright">
				<p>
					<b>{{ regionName(selectedRegion) }}:</b>
					{{ regionValue(selectedRegion) }}
				</p>
				<p v-for="unit of extraUnits" :key="unit">
					<b>{{ regionName(unit) }}:</b> {{ regionValue(unit) }}
				</p>
			</l-control>

			<l-geo-json
				v-for="feature in geoData ? geoData.features : []"
				:key="feature.properties.DataId"
				:geojson="feature"
				:options="regionStyle(feature)"
				@click="regionSelected"
				@mouseover="regionHover"
				@mouseout="regionUnhover"
			></l-geo-json>
		</l-map>
	</div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import {
	GeoJSONOptions,
	GeoJSONEvent,
	LatLng,
	LeafletMouseEvent,
} from 'leaflet'
import { LMap, LTileLayer, LGeoJson, LControl } from 'vue2-leaflet'
import { interpolatePlasma } from 'd3-scale-chromatic'
import { scaleLinear } from 'd3-scale'

import {
	timePeriods,
	warmingPeriods,
	members,
	indicators,
	variants,
	metrics,
	strands,
	emissions,
	resolutions,
	MemberType,
	maxAdditional,
} from '@/store/state'
import { MutationTypes } from '@/store/mutations'

@Component({
	name: 'Map',
	components: { LMap, LTileLayer, LGeoJson, LControl },
})
export default class Map extends Vue {
	readonly bounds: LatLng[] = [new LatLng(45, -21), new LatLng(65, 13)]
	localMinMax: boolean = true

	getScaleValue(scale: any, i: number) {
		if (i == 1) return scale.domain()[1].toFixed(1)
		if (i == 20) return scale.domain()[0].toFixed(1)
		if (i == 10) return ((scale.domain()[0] + scale.domain()[1]) / 2).toFixed(1)
	}

	get colourScale() {
		return this.$tStore
			? indicators[this.$tStore.state.indicator].scale
				? indicators[this.$tStore.state.indicator].scale
				: interpolatePlasma
			: interpolatePlasma
	}

	get bgLayer() {
		return {
			name: 'OpenStreetMap Mapnik',
			url: 'https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png',
			attribution:
				'&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
		}
	}

	get climateData() {
		return this.$tStore ? this.$tStore.state.data : {}
	}

	get scales() {
		return this.$tStore ? this.$tStore.state.scales : []
	}

	get spatialUnit(): string {
		return this.$tStore ? this.$tStore.state.spatialUnit : '0'
	}

	get year(): number {
		return this.$tStore
			? this.$tStore.state.emission === 'warming' &&
			  this.$tStore.state.timePeriod !== 't9' &&
			  this.$tStore.state.timePeriod !== 't10'
				? warmingPeriods[this.$tStore.state.timePeriod].id
				: timePeriods[this.$tStore.state.timePeriod].id
			: 1996
	}

	get member(): MemberType {
		return this.$tStore ? this.$tStore.state.member : 'median'
	}

	get memberName(): string {
		return this.$tStore ? members[this.$tStore.state.member].name : ''
	}

	get indicatorName(): string {
		return this.$tStore ? indicators[this.$tStore.state.indicator].name : ''
	}

	get metricName(): string {
		return this.$tStore ? metrics[this.$tStore.state.metric].name : ''
	}

	get geoData() {
		return this.$tStore?.state.geoData
	}

	get additionalSpatialUnits() {
		return this.$tStore?.state.additionalSpatialUnits
	}

	regionStyle(event: GeoJSONEvent): GeoJSONOptions {
		if (this.$tStore.getters.loading) {
			return {
				style: {
					weight: 0,
					fillOpacity: 0,
				},
			}
		}
		const hasDataId = event.properties.hasOwnProperty('DataId')
		if (!hasDataId) {
			// This should not be the case any more - have checked that all spatial units
			// have an ID.  Kept for debug reasons
			console.error(event.properties.Name, 'has no data ID')
		}
		const dataId: string = `${event.properties.DataId}`
		if (!this.climateData[dataId]) {
			// This happens when data is missing, which shouldn't be the case.
			// console.warn('Do not have data for location', event.properties.Name)
			return {
				style: {
					color: 'rgba(0,0,0,0)',
					weight: 0,
					fillOpacity: 0,
				},
				// @ts-ignore
				originalWeight: 0,
			}
		}
		const dataVal = this.climateData[dataId][this.year][this.member]
		let weight = 0.5
		if (this.additionalSpatialUnits.includes(dataId)) {
			weight = 1.5
		}
		if (this.spatialUnit === dataId) {
			weight = 2.5
		}
		return {
			style: {
				color: hasDataId ? 'black' : 'red',
				fillColor:
					hasDataId && this.spatialUnit && dataVal
						? this.colourScale(this.scale(dataVal))
						: 'none',
				weight,
				fillOpacity: 0.75,
				className: 'spatial-unit',
			},
			// @ts-ignore
			originalWeight: weight,
			data: event.properties,
		}
	}

	get scale() {
		if (this.localMinMax) {
			const vals = []
			for (let s of this.$tStore.state.spatialNames) {
				if (
					this.climateData &&
					this.climateData[s.val] &&
					this.climateData[s.val][this.year]
				) {
					const val = this.climateData[s.val][this.year][this.member]
					if (val) {
						vals.push(val)
					}
				}
			}
			return scaleLinear().domain([Math.min(...vals), Math.max(...vals)])
		} else {
			return this.scales[this.year]
		}
	}

	get selectedRegion() {
		return this.$tStore?.state.spatialUnit
	}

	get adding() {
		return this.$tStore?.state.addingAdditionalUnits
	}

	get remaining() {
		return maxAdditional - this.$tStore?.state.additionalSpatialUnits.length
	}

	get extraUnits() {
		return this.$tStore?.state.additionalSpatialUnits
	}

	regionValue(id: string) {
		if (
			// Data is missing
			!this.climateData ||
			// Data is missing for this spatial unit
			!this.climateData[id] ||
			// Data is missing for this time period.  This is usually because
			// this.year has updated to be a warming period before the data
			// has been updated.
			!this.climateData[id][this.year]
		) {
			return undefined
		}
		return this.climateData[id][this.year][this.member]
	}

	regionSelected(event: LeafletMouseEvent) {
		const regionId = `${event.target.options.data.DataId}`
		if (this.adding) {
			this.$tStore.commit(MutationTypes.TOGGLE_EXTRA_SPATIAL_UNIT, regionId)
		} else {
			this.$tStore.commit(MutationTypes.SET_SPATIAL_UNIT, regionId)
		}
	}

	regionName(id: string) {
		for (let spatialName of this.$tStore?.state.spatialNames) {
			if (spatialName.val === id) {
				return spatialName.name
			}
		}
		return null
	}

	regionHover(region: LeafletMouseEvent) {
		region.target.setStyle({
			weight: 2,
		})
	}

	regionUnhover(region: LeafletMouseEvent) {
		region.target.setStyle({
			weight: region.target.options.originalWeight,
		})
	}

	downloadGeoJson() {
		if (this.geoData === null) {
			return
		}
		this.$tStore.commit(MutationTypes.SET_LOADING, undefined)
		// @ts-ignore
		for (let feature of this.geoData.features) {
			feature.properties.Value = this.regionValue(feature.properties.DataId)
		}
		const dataStr =
			'data:text/json;charset=utf-8,' +
			encodeURIComponent(JSON.stringify(this.geoData))
		const link = document.createElement('a')
		link.setAttribute('href', dataStr)

		const iid = indicators[this.$tStore.state.indicator].id
		const vid = variants[this.$tStore.state.variant].id
		const mid = metrics[this.$tStore.state.metric].id
		const sid = strands[this.$tStore.state.strand].id
		const eid = emissions[this.$tStore.state.emission].id
		const rid = resolutions[this.$tStore.state.resolution].id
		const tid = timePeriods[this.$tStore.state.timePeriod].id
		const memid = members[this.$tStore.state.member].id
		const filename = `${iid}_${vid}_${mid}_${sid}_${eid}_${rid}_${tid}_${memid}.geojson`
		link.setAttribute('download', filename)
		link.click()
		this.$tStore.commit(MutationTypes.SET_FINISHED_LOADING, undefined)
	}
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/climpolVars.scss';

.map-container {
	.map {
		height: 100%;

		.legend {
			background-color: $mainBgColor;
			border: 2px solid $medium;
			border-radius: 8px;
			padding: 8px;
			display: flex;
			flex-direction: column;
			align-content: flex-start;

			h4 {
				margin: 4px;
			}
			p {
				margin: 0;
				text-align: start;
			}

			&.color {
				margin-bottom: 8px;
				margin-left: 8px;
				padding: 0;
				box-shadow: 2px 2px 8px $medium;

				&:hover {
					margin-left: 9px;
					margin-bottom: 7px;
					box-shadow: 1px 1px 8px $medium;
				}
			}

			.colorbar {
				cursor: pointer;
				padding: 4px 8px 8px 8px;
				display: flex;
				flex-direction: column;

				p {
					padding-bottom: 4px;
				}

				.line {
					flex: 0 0 5px;
					height: 5px;

					display: flex;
					flex-direction: row;
					.box {
						width: 20px;
						height: 5px;
					}
					.label {
						margin-left: 4px;
						font-size: 12px;
						font-weight: bold;
						align-self: center;
					}
				}
			}

			&.toggle {
				margin-bottom: 32px;
				align-items: flex-start;

				input[type='radio'] {
					accent-color: $medium;
				}
			}
		}

		.legend.multiple {
			width: 150px;
		}

		.legend.value {
			min-width: 150px;
			max-width: 210px;
			p {
				text-align: center;
			}
		}
	}
}
</style>
