import { library } from '@fortawesome/fontawesome-svg-core'
import {
	faAngleDoubleLeft,
	faAngleDoubleRight,
	faAngleDown,
	faAngleUp,
	faCogs,
	faInfoCircle,
	faTimesCircle,
} from '@fortawesome/free-solid-svg-icons'
import {
	FontAwesomeIcon,
	FontAwesomeLayers,
	FontAwesomeLayersText,
} from '@fortawesome/vue-fontawesome'
import Vue from 'vue'

// @ts-ignore
library.add(faAngleDoubleRight)
// @ts-ignore
library.add(faAngleDoubleLeft)
// @ts-ignore
library.add(faAngleDown)
// @ts-ignore
library.add(faAngleUp)
// @ts-ignore
library.add(faCogs)
// @ts-ignore
library.add(faInfoCircle)
// @ts-ignore
library.add(faTimesCircle)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
