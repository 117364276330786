<template>
	<chart id="chart-body" :options="chartOptions" ref="chartEl">
		<rect x="100" y="100" width="100" height="100" fill="red"></rect>
	</chart>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { Chart } from 'highcharts-vue'
import { labels } from '@/lib/labels'
import {
	indicators,
	members,
	metrics,
	emissions,
	strands,
	EmissionType,
	StrandType
} from '@/store/state'
import { schemeSet2 } from 'd3-scale-chromatic'
import Plausible from 'plausible-tracker'

const { trackEvent } = Plausible({
	domain: 'uk-cri.org'
})

@Component({
	components: {
		Chart
	}
})
export default class CRIChart extends Vue {
	readonly labels: any = labels
	readonly s = this.$tStore.state

	getSpatialUnitName(id: string) {
		for (let su of this.s.spatialNames) {
			if (id === su.val) {
				return su.name
			}
		}
		return ''
	}

	getScenarioName(emission: EmissionType, strand: StrandType) {
		return `${emissions[emission].name}, ${strands[strand].name}`
	}

	get chartData() {
		return this.$tStore?.state.data
	}

	get compareData() {
		return this.$tStore?.state.compareData
	}

	get chartIdStr() {
		let idStr = `${this.s.indicator}/${this.s.variant}/${this.s.metric}/${this.s.emission}/${this.s.strand}/${this.s.member}/${this.s.resolution}/${this.s.spatialUnit}`
		if (this.s.additionalScenario) {
			idStr += ` vs ${this.s.additionalScenario.emission}/${this.s.additionalScenario.strand}`
		}
		if (this.s.additionalSpatialUnits.length > 0) {
			for (let extraUnit of this.s.additionalSpatialUnits) {
				idStr += ` + region ${extraUnit}`
			}
		}
		return idStr
	}

	get chartOptions() {
		const that = this
		const chartOpts = {
			chart: {
				zoomType: 'y',
				events: {
					afterPrint: () => {
						try {
							trackEvent('Print chart')
						} catch {
							console.warn('Unable to use analytics')
						}
					}
				}
			},
			title: {
				text: `${indicators[this.s.indicator].name}`,
				style: {
					fontSize: '13px'
				}
			},
			subtitle: {
				text: `${members[this.s.member].name}`,
				style: {
					fontSize: '12px'
				}
			},
			xAxis: {
				title: {
					text: this.s.emission === 'warming' ? labels.warmingLevel : null
				},
				labels: {
					enabled: true
				},
				crosshair: true
			},
			yAxis: {
				title: {
					text: metrics[this.s.metric].name
				}
			},
			tooltip: {
				xDateFormat: '%d %b %Y',
				shared: true,
				enabled: true,
				formatter(): string {
					let s = ''
					// Special case for warming level on x axis
					const xString =
						that.s.emission === 'warming'
							? // @ts-ignore
							  `${this.x}°C`
							: // @ts-ignore
							  `${this.x - 15} - ${this.x + 14}`
					// @ts-ignore
					if (this.points.length <= 2) {
						// @ts-ignore
						s += `<b>${xString}: </b>${this.y}`
						// @ts-ignore
						for (let point of this.points) {
							if (point.point?.hasOwnProperty('low')) {
								s += `<br/><b>Range: </b>${point.point.low} to ${point.point.high}`
							}
						}
					} else {
						// @ts-ignore
						s += `<b>${xString}</b>`
						// @ts-ignore
						for (let point of this.points) {
							if (point.point?.hasOwnProperty('low')) {
								s += `<br/><b>${point.series.name}: </b>${point.point.low} to ${point.point.high}`
							} else {
								s += `<br/><b>${point.series.name}: </b>${point.point.y}`
							}
						}
					}
					return s
				}
			},
			plotOptions: {
				column: {
					pointPadding: -0.1,
					borderWidth: 0
				},
				line: {
					states: {
						hover: {
							lineWidthPlus: 0
						}
					},
					events: {
						legendItemClick() {
							return false
						}
					}
				}
			},
			exporting: {
				menuItemDefinitions: {
					downloadPNG: {
						onclick: () => {
							try {
								trackEvent('Download PNG')
							} catch {
								console.warn('Unable to use analytics')
							}
							// @ts-ignore
							this.$refs.chartEl.chart.exportChartLocal()
						}
					},
					downloadPDF: {
						onclick: () => {
							try {
								trackEvent('Download PDF')
							} catch {
								console.warn('Unable to use analytics')
							}
							// @ts-ignore
							this.$refs.chartEl.chart.exportChartLocal({
								type: 'application/pdf'
							})
						}
					},
					downloadSVG: {
						onclick: () => {
							try {
								trackEvent('Download SVG')
							} catch {
								console.warn('Unable to use analytics')
							}
							// @ts-ignore
							this.$refs.chartEl.chart.exportChartLocal({
								type: 'image/svg+xml'
							})
						}
					},
					downloadJPEG: undefined
				}
			},
			series: [] as Highcharts.SeriesOptionsType[]
		}

		const spatialDataPresent: boolean = this.s.additionalSpatialUnits.length > 0
		const compareDataPresent: boolean =
			this.compareData && this.compareData[this.s.spatialUnit] ? true : false
		if (this.chartData[this.s.spatialUnit]) {
			let name: string = ''
			if (spatialDataPresent) {
				name += `${this.getSpatialUnitName(this.s.spatialUnit)}\n`
			}
			if (compareDataPresent) {
				name += `${this.getScenarioName(this.s.emission, this.s.strand)}`
			}

			const unitData = Object.entries(
				this.chartData[this.s.spatialUnit]
			).sort((a, b) => (a[1].year < b[1].year ? -1 : 1))

			chartOpts.series.push({
				type: 'line',
				name,
				showInLegend: spatialDataPresent || compareDataPresent,
				data: unitData.map(kv => [kv[1].year, kv[1][this.s.member]]),
				color: '#ee3e2c',
				opacity: 1,
				states: {
					inactive: {
						opacity: 0.7
					}
				}
			})

			chartOpts.series.push({
				type: 'arearange',
				name: `${name} range`,
				data: unitData.map(kv => [
					kv[1].year,
					kv[1].min !== undefined ? kv[1].min : kv[1].pc10,
					kv[1].max !== undefined ? kv[1].max : kv[1].pc90
				]),
				color: '#f2847e',
				lineWidth: 0,
				marker: {
					enabled: false
				},
				opacity: 0.5,
				linkedTo: ':previous',
				states: {
					inactive: {
						opacity: 0.35
					}
				}
			})
		}

		let i = 0
		if (compareDataPresent) {
			let name: string = ''
			if (spatialDataPresent) {
				name += `${this.getSpatialUnitName(this.s.spatialUnit)}\n`
			}
			if (compareDataPresent && this.s.additionalScenario != null) {
				name += `${this.getScenarioName(
					this.s.additionalScenario.emission,
					this.s.additionalScenario.strand
				)}`
			}

			const compareData = Object.entries(
				this.compareData[this.s.spatialUnit]
			).sort((a, b) => (a[1].year < b[1].year ? -1 : 1))

			chartOpts.series.push({
				type: 'line',
				name,
				showInLegend: true,
				data: compareData.map(kv => [kv[1].year, kv[1][this.s.member]]),
				color: schemeSet2[i],
				opacity: 1,
				states: {
					inactive: {
						opacity: 0.7
					}
				}
			})

			chartOpts.series.push({
				type: 'arearange',
				name: `${name} range`,
				data: compareData.map(kv => [
					kv[1].year,
					kv[1].min !== undefined ? kv[1].min : kv[1].pc10,
					kv[1].max !== undefined ? kv[1].max : kv[1].pc90
				]),
				color: schemeSet2[i],
				lineWidth: 0,
				marker: {
					enabled: false
				},
				opacity: 0.5,
				linkedTo: ':previous',
				states: {
					inactive: {
						opacity: 0.35
					}
				}
			})
			i++
		}

		if (spatialDataPresent) {
			for (let spatialUnit of this.s.additionalSpatialUnits) {
				if (spatialUnit === this.s.spatialUnit) {
					// If the "additional" spatial unit is also the primary one,
					// don't plot it again.
					continue
				}
				let name: string = ''
				if (spatialDataPresent) {
					name += `${this.getSpatialUnitName(spatialUnit)}\n`
				}
				if (compareDataPresent && this.s.additionalScenario != null) {
					name += `${this.getScenarioName(this.s.emission, this.s.strand)}`
				}

				const unitData = Object.entries(
					this.chartData[spatialUnit]
				).sort((a, b) => (a[1].year < b[1].year ? -1 : 1))

				chartOpts.series.push({
					type: 'line',
					name,
					showInLegend: true,
					data: unitData.map(kv => [kv[1].year, kv[1][this.s.member]]),
					color: schemeSet2[i],
					opacity: 1,
					states: {
						inactive: {
							opacity: 0.7
						}
					}
				})

				chartOpts.series.push({
					type: 'arearange',
					name: `${name} range`,
					data: unitData.map(kv => [
						kv[1].year,
						kv[1].min !== undefined ? kv[1].min : kv[1].pc10,
						kv[1].max !== undefined ? kv[1].max : kv[1].pc90
					]),
					color: schemeSet2[i],
					lineWidth: 1,
					marker: {
						enabled: false
					},
					opacity: 0.5,
					fillOpacity: 0.1,
					linkedTo: ':previous',
					states: {
						inactive: {
							opacity: 0.25
						}
					}
				})
				i++

				if (compareDataPresent) {
					let name: string = ''
					if (spatialDataPresent) {
						name += `${this.getSpatialUnitName(spatialUnit)}\n`
					}
					if (compareDataPresent && this.s.additionalScenario != null) {
						name += `${this.getScenarioName(
							this.s.additionalScenario.emission,
							this.s.additionalScenario.strand
						)}`
					}
					chartOpts.series.push({
						type: 'line',
						name,
						showInLegend: true,
						data: Object.entries(this.compareData[spatialUnit]).map(kv => [
							kv[1].year,
							kv[1][this.s.member]
						]),
						color: schemeSet2[i],
						opacity: 1,
						states: {
							inactive: {
								opacity: 0.7
							}
						}
					})

					chartOpts.series.push({
						type: 'arearange',
						name: `${name} range`,
						data: Object.entries(this.compareData[spatialUnit]).map(kv => [
							kv[1].year,
							kv[1].min !== undefined ? kv[1].min : kv[1].pc10,
							kv[1].max !== undefined ? kv[1].max : kv[1].pc90
						]),
						color: schemeSet2[i],
						lineWidth: 0,
						marker: {
							enabled: false
						},
						opacity: 0.5,
						linkedTo: ':previous',
						states: {
							inactive: {
								opacity: 0.35
							}
						}
					})
				}
			}
		}

		return chartOpts
	}
}
</script>

<style lang="scss">
@import '@/assets/scss/climpolVars.scss';

.highcharts-background {
	fill: white;
}

.highcharts-legend-item,
.highcharts-legend-item text {
	cursor: default !important;
}

.highcharts-menu-item:hover {
	background-color: $dark !important;
}
</style>
