<template>
	<div class="control-panel">
		<div class="scrollable" :class="{ hidden }">
			<md-field>
				<label for="category">{{ labels.category }} </label>
				<md-select
					v-model="category"
					name="category"
					id="category"
					@md-selected="hidden = true"
				>
					<md-option
						v-for="(category, categoryVal) in categories"
						:key="category.id"
						:value="categoryVal"
					>
						{{ category.name }}
					</md-option>
				</md-select>
			</md-field>
			<md-field>
				<label for="indicator" :title="labels.hoverInd"
					>{{ labels.indicator }}
				</label>
				<md-select
					v-model="indicator"
					name="indicator"
					id="indicator"
					@md-selected="hidden = true"
					:title="getDescFor(indicator)"
				>
					<md-option
						v-for="(indicator, indicatorVal) in indicators"
						:key="indicator.id"
						:value="indicatorVal"
						:title="getDescFor(indicatorVal)"
					>
						{{ indicator.name }}
					</md-option>
				</md-select>
			</md-field>
			<md-field
				v-if="
					Object.keys(variants).length > 1 || !variants.hasOwnProperty('none')
				"
				:title="labels.hoverVar"
			>
				<label for="variant">{{ labels.variant }} </label>
				<md-select
					v-model="variant"
					name="variant"
					id="variant"
					:disabled="Object.keys(variants).length < 2"
					@md-selected="hidden = true"
				>
					<md-option
						v-for="(variant, variantVal) in variants"
						:key="variant.id"
						:value="variantVal"
					>
						{{ variant.name }}
					</md-option>
				</md-select>
			</md-field>
			<md-field :title="labels.hoverMet">
				<label for="metric">{{ labels.metric }} </label>
				<md-select
					v-model="metric"
					name="metric"
					id="metric"
					:disabled="Object.keys(metrics).length < 2"
					@md-selected="hidden = true"
				>
					<md-option
						v-for="(metric, metricVal) in metrics"
						:key="metric.id"
						:value="metricVal"
					>
						{{ metric.name }}
					</md-option>
				</md-select>
			</md-field>
			<md-field>
				<label for="emission" :title="labels.hoverScen"
					>{{ labels.emission }}
				</label>
				<md-select
					v-model="emission"
					name="emission"
					id="emission"
					:disabled="addingScenarios && emission === 'warming'"
					@md-selected="hidden = true"
					:title="getDescFor(emission)"
				>
					<md-option
						v-for="(emission, emissionVal) in emissions"
						:key="emission.id"
						:value="emissionVal"
						:disabled="
							addingScenarios &&
								strand === extraStrand &&
								emissionVal === extraEmission
						"
						:title="getDescFor(emissionVal)"
					>
						{{ emission.name }}
					</md-option>
				</md-select>
			</md-field>
			<md-field>
				<label for="strand" :title="labels.hoverStrand"
					>{{ labels.strand }}
				</label>
				<md-select
					v-model="strand"
					name="strand"
					id="strand"
					:disabled="Object.keys(strands).length < 2"
					@md-selected="hidden = true"
					:title="getDescFor(strand)"
				>
					<md-option
						v-for="(strand, strandVal) in strands"
						:key="strand.id"
						:value="strandVal"
						:disabled="
							addingScenarios &&
								emission === extraEmission &&
								strandVal === extraStrand
						"
						:title="getDescFor(strandVal)"
					>
						{{ strand.name }}
					</md-option>
				</md-select>
			</md-field>
			<md-field>
				<label for="member" :title="labels.hoverMem"
					>{{ labels.member }}
				</label>
				<md-select
					v-model="member"
					name="member"
					id="member"
					@md-selected="hidden = true"
					:title="getDescFor(member)"
				>
					<md-option
						v-for="(member, memberVal) in members"
						:key="member.id"
						:value="memberVal"
						@md-selected="hidden = true"
						:title="getDescFor(memberVal)"
					>
						{{ member.name }}
					</md-option>
				</md-select>
			</md-field>
			<md-field>
				<label for="resolution" :title="labels.hoverRes"
					>{{ labels.resolution }}
				</label>
				<md-select
					v-model="resolution"
					name="resolution"
					id="resolution"
					@md-selected="hidden = true"
					:title="getDescFor(resolution)"
				>
					<md-option
						v-for="(resolution, resolutionVal) in resolutions"
						:key="resolution.id"
						:value="resolutionVal"
						@md-selected="hidden = true"
						:title="getDescFor(resolutionVal)"
					>
						{{ resolution.name }}
					</md-option>
				</md-select>
			</md-field>
			<md-field :title="labels.hoverSpU">
				<label for="spatialUnit">{{ labels.spatialUnit }} </label>
				<md-select
					v-model="spatialUnit"
					name="spatialUnit"
					id="spatialUnit"
					@md-selected="hidden = true"
				>
					<md-option
						v-for="spatialUnit in spatialUnitNames"
						:key="spatialUnit.name"
						:value="spatialUnit.val"
						:disabled="additionalSpatialUnits.includes(spatialUnit.val)"
					>
						{{ spatialUnit.name }}
					</md-option>
				</md-select>
			</md-field>
			<md-field
				:title="emission === 'warming' ? labels.hoverLevel : labels.hoverTime"
			>
				<label for="timePeriod"
					>{{
						emission === 'warming' ? labels.warmingLevel : labels.timePeriod
					}}
				</label>
				<md-select
					v-model="timePeriod"
					name="timePeriod"
					id="timePeriod"
					@md-selected="hidden = true"
				>
					<md-option
						v-for="(timePeriod, timePeriodVal) in timePeriods"
						:key="timePeriod.id"
						:value="timePeriodVal"
					>
						{{ timePeriod.name }}
					</md-option>
				</md-select>
			</md-field>
			<div class="scenario-group" :class="{ active: addingScenarios }">
				<label v-if="addingScenarios" class="title"
					>Compare with Scenario</label
				>
				<md-field v-if="addingScenarios"
					><label for="extraEmission">{{ labels.emission }} </label>
					<md-select
						v-model="extraEmission"
						name="extraEmission"
						id="extraEmission"
						:disabled="emission === 'warming'"
						@md-selected="hidden = true"
					>
						<md-option
							v-for="(e, emissionVal) in emissions"
							:key="e.id"
							:value="emissionVal"
							:disabled="
								(strand === extraStrand && emissionVal === emission) ||
									(emission !== 'warming' && emissionVal === 'warming')
							"
						>
							{{ e.name }}
						</md-option>
					</md-select>
				</md-field>
				<md-field v-if="addingScenarios">
					<label for="extraStrand">{{ labels.strand }} </label>
					<md-select
						v-model="extraStrand"
						name="extraStrand"
						id="extraStrand"
						@md-selected="hidden = true"
					>
						<md-option
							v-for="(s, strandVal) in extraStrands"
							:key="s.id"
							:value="strandVal"
							:disabled="emission === extraEmission && strandVal === strand"
						>
							{{ s.name }}
						</md-option>
					</md-select>
				</md-field>
				<md-button
					class="md-raised md-primary"
					@click="toggleAddScenarios"
					:title="
						addingScenarios
							? 'Click to return to a single scenario'
							: 'Click to select another Emissions Scenario and Strand to compare'
					"
					:disabled="addingRegions"
				>
					{{ addingScenarios ? labels.addingScenarios : labels.addScenarios }}
				</md-button>
			</div>
			<md-button
				class="md-raised md-primary"
				@click="toggleAddRegions"
				:title="
					addingRegions
						? 'Click to return to a single region'
						: 'Click to select up to 3 additional regions to compare'
				"
				:disabled="addingScenarios"
			>
				{{ addingRegions ? labels.addingRegions : labels.addRegions }}
			</md-button>
			<md-button
				class="md-raised md-primary"
				@click="download"
				title="Click to download the data used for this combination of settings, in CSV format"
				:disabled="addingScenarios || addingRegions"
			>
				{{ labels.download }}
			</md-button>
			<md-button
				class="md-raised md-primary"
				@click="$emit('geoJsonDownload')"
				title="Click to download the data visible on the map, in GeoJSON format"
				:disabled="addingScenarios || addingRegions"
			>
				{{ labels.downloadMap }}
			</md-button>
		</div>
		<div class="showhide" @click="toggleHidden">
			<font-awesome-icon icon="cogs" />
			<font-awesome-icon icon="angle-double-right" v-if="hidden" />
			<font-awesome-icon icon="angle-double-left" v-else />
		</div>
	</div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import * as s from '@/store/state'
import { MutationTypes } from '@/store/mutations'
import { ActionTypes } from '@/store/actions'
import { labels, hoverDescriptions } from '@/lib/labels'

@Component({
	name: 'ControlPanel',
	components: {}
})
export default class ControlPanel extends Vue {
	hidden: boolean = window.innerWidth < 900
	lastWidth: number = window.innerWidth
	readonly labels = labels

	readonly categories = s.categories
	get category() {
		return this.$tStore.state.category
	}
	set category(value: s.CategoryType) {
		this.$tStore.commit(MutationTypes.SET_CATEGORY, value)
	}

	get indicators() {
		// The list of available indicators depends on the indicator category.
		// This mapping is defined in s.indicatorFilter
		return Object.fromEntries(
			(Object.entries(s.indicators) as Array<
				[s.IndicatorType, any]
			>).filter(value => s.indicatorFilter[this.category].includes(value[0]))
		)
	}
	get indicator() {
		return this.$tStore.state.indicator
	}
	set indicator(value: s.IndicatorType) {
		this.$tStore.commit(MutationTypes.SET_INDICATOR, value)
	}

	get variants() {
		// The list of available variants depends on the indicator.
		// This mapping is defined in s.variantFilter
		return Object.fromEntries(
			(Object.entries(s.variants) as Array<
				[s.VariantType, any]
			>).filter(value => s.variantFilter[this.indicator].includes(value[0]))
		)
	}
	get variant() {
		return this.$tStore.state.variant
	}
	set variant(value: s.VariantType) {
		this.$tStore.commit(MutationTypes.SET_VARIANT, value)
	}

	get metrics() {
		// Special cases.  For the hotday/hotmonth/wetmonth variants of
		// record-breaking temperatures, only one metric is available.
		// In ALL other cases the metric only depends upon the indicator
		if (this.indicator === 'recordtemp') {
			// (technically the check for recordtemp is unnecessary, since these
			// variants only exist on that indicator)
			if (this.variant === 'hotday') {
				return {
					days: s.metrics.days
				}
			} else if (this.variant === 'hotmonth' || this.variant === 'wetmonth') {
				return {
					months: s.metrics.months
				}
			}
		}
		// Other special case - when the 10yr variant is selected, the "chance"
		// metric is available in addition to the "percent" one
		if (this.variant === 'y10') {
			return Object.fromEntries(
				(Object.entries(s.metrics) as Array<[s.MetricType, any]>).filter(
					value => value[0] === 'chance' || value[0] === 'percent'
				)
			)
		}
		// The list of available metrics depends on the indicator.
		// This mapping is defined in s.metricFilter
		return Object.fromEntries(
			(Object.entries(s.metrics) as Array<[s.MetricType, any]>).filter(value =>
				s.metricFilter[this.indicator].includes(value[0])
			)
		)
	}
	get metric() {
		return this.$tStore.state.metric
	}
	set metric(value: s.MetricType) {
		this.$tStore.commit(MutationTypes.SET_METRIC, value)
	}

	get emissions() {
		// Certain indicators have a limited set of emissions
		if (s.specialIndicators.includes(this.indicator)) {
			return Object.fromEntries(
				(Object.entries(s.emissions) as Array<
					[s.EmissionType, any]
				>).filter(value => s.specialValues.emissions.includes(value[0]))
			)
		}
		return s.emissions
	}
	get emission() {
		return this.$tStore.state.emission
	}
	set emission(value: s.EmissionType) {
		this.$tStore.commit(MutationTypes.SET_EMISSIONS, value)
		if (value === 'warming' && this.addingScenarios) {
			this.$tStore.commit(MutationTypes.SET_EXTRA_SCENARIO, {
				emission: 'warming',
				strand: this.extraStrand === 'hadGem' ? 'cmip' : 'hadGem'
			})
		}
	}

	get strands() {
		// Certain indicators have a specific limited set of strands
		if (s.specialIndicators.includes(this.indicator)) {
			return Object.fromEntries(
				(Object.entries(s.strands) as Array<
					[s.StrandType, any]
				>).filter(value => s.specialValues.strands.includes(value[0]))
			)
		}

		// Otherwise the list of available strands depends on the emission.
		// This mapping is defined in s.strandFilter
		return Object.fromEntries(
			(Object.entries(s.strands) as Array<[s.StrandType, any]>).filter(value =>
				s.strandFilter[this.emission].includes(value[0])
			)
		)
	}
	get strand() {
		return this.$tStore.state.strand
	}
	set strand(value: s.StrandType) {
		this.$tStore.commit(MutationTypes.SET_STRAND, value)
	}

	get extraEmission() {
		return this.$tStore.state.additionalScenario
			? this.$tStore.state.additionalScenario.emission
			: 'rcp85'
	}
	set extraEmission(value: s.EmissionType) {
		this.$tStore.commit(MutationTypes.SET_EXTRA_SCENARIO, {
			emission: value,
			strand: this.extraStrand
		})
	}

	get extraStrands() {
		// The list of available strands depends on the emission.
		// This mapping is defined in s.strandFilter
		return Object.fromEntries(
			(Object.entries(s.strands) as Array<[s.StrandType, any]>).filter(value =>
				s.strandFilter[this.extraEmission].includes(value[0])
			)
		)
	}
	get extraStrand() {
		return this.$tStore.state.additionalScenario
			? this.$tStore.state.additionalScenario.strand
			: 'prob'
	}
	set extraStrand(value: s.StrandType) {
		this.$tStore.commit(MutationTypes.SET_EXTRA_SCENARIO, {
			emission: this.extraEmission,
			strand: value
		})
	}

	get members() {
		// Special case - for the warming scenario not all CMIP5 members are present
		if (this.emission === 'warming' && this.strand === 'cmip') {
			return Object.fromEntries(
				(Object.entries(s.members) as Array<
					[s.MemberType, any]
				>).filter(value => s.cmipWarmingMembers.includes(value[0]))
			)
		}

		// The list of available members depends on the strand.
		// This mapping is defined in s.memberFilter
		return Object.fromEntries(
			(Object.entries(s.members) as Array<[s.MemberType, any]>).filter(value =>
				s.memberFilter[this.strand].includes(value[0])
			)
		)
	}
	get member() {
		return this.$tStore.state.member
	}
	set member(value: s.MemberType) {
		this.$tStore.commit(MutationTypes.SET_MEMBER, value)
	}

	get resolutions() {
		// Certain indicators have a fixed set of resolutions.
		if (s.specialIndicators.includes(this.indicator)) {
			// return s.specialValues
			return Object.fromEntries(
				(Object.entries(s.resolutions) as Array<
					[s.ResolutionType, any]
				>).filter(value => s.specialValues.resolutions.includes(value[0]))
			)
		}
		return s.resolutions
	}

	get resolution() {
		return this.$tStore.state.resolution
	}
	set resolution(value: s.ResolutionType) {
		this.$tStore.commit(MutationTypes.SET_RESOLUTION, value)
	}

	get spatialUnit() {
		return this.$tStore.state.spatialUnit
	}
	set spatialUnit(value: s.SpatialUnitType) {
		this.$tStore.commit(MutationTypes.SET_SPATIAL_UNIT, value)
	}

	get spatialUnitNames() {
		return this.$tStore.state.spatialNames
	}

	get timePeriods() {
		if (this.emission === 'warming') {
			return s.warmingPeriods
		}
		return s.timePeriods
	}

	get timePeriod() {
		return this.$tStore.state.timePeriod
	}
	set timePeriod(value: s.TimePeriodType) {
		this.$tStore.commit(MutationTypes.SET_TIME_PERIOD, value)
	}

	get additionalSpatialUnits() {
		return this.$tStore.state.additionalSpatialUnits
	}

	@Watch('indicator')
	@Watch('variant')
	@Watch('metric')
	@Watch('strand')
	@Watch('emission')
	@Watch('resolution')
	settingsChanged() {
		this.$tStore.dispatch(ActionTypes.GET_DATA, undefined)
	}

	@Watch('indicator')
	@Watch('variant')
	@Watch('metric')
	@Watch('extraStrand')
	@Watch('extraEmission')
	@Watch('resolution')
	compareSettingsChanged() {
		this.$tStore.dispatch(ActionTypes.GET_COMPARE_DATA, undefined)
	}

	@Watch('resolution')
	async resolutionChanged() {
		await this.$tStore.dispatch(ActionTypes.GET_GEODATA, undefined)
		switch (this.resolution) {
			// We've changed the resolution.
			// Choose the spatial unit containing the University of Reading
			case 'nation':
				this.spatialUnit = '1'
				break
			case 'region':
				this.spatialUnit = '2'
				break
			case 'lrf':
				this.spatialUnit = '43'
				break
			case 'nuts2':
				this.spatialUnit = 'UKJ1'
				break
			case 'nuts3':
				this.spatialUnit = 'UKJ11'
				break
			case 'lau':
				this.spatialUnit = 'E06000038'
				break
			case 'km12':
				this.spatialUnit = '1384'
				break
		}
	}

	get addingRegions() {
		return this.$tStore?.state.addingAdditionalUnits
	}

	toggleAddRegions() {
		if (this.addingRegions) {
			this.$tStore.commit(MutationTypes.CLEAR_EXTRA_SPATIAL_UNITS, undefined)
		} else {
			this.hidden = true
		}
		this.$tStore.commit(MutationTypes.TOGGLE_ADDING_REGIONS, undefined)
	}

	get addingScenarios() {
		return this.$tStore?.state.addingAdditionalScenarios
	}

	toggleAddScenarios() {
		if (this.addingScenarios) {
			this.$tStore.commit(MutationTypes.SET_EXTRA_SCENARIO, null)
		} else {
			const newScenario: s.Scenario = {
				emission: this.emission === 'warming' ? 'warming' : 'rcp85',
				// Choose HadGEM unless that's what is already selected
				strand:
					(this.emission === 'rcp85' || this.emission === 'warming') &&
					this.strand === 'hadGem'
						? 'cmip'
						: 'hadGem'
			}
			this.$tStore.commit(MutationTypes.SET_EXTRA_SCENARIO, newScenario)
			this.$tStore.dispatch(ActionTypes.GET_COMPARE_DATA, undefined)
		}
		this.$tStore.commit(MutationTypes.TOGGLE_ADDING_SCENARIOS, undefined)
	}

	download() {
		this.$tStore.dispatch(ActionTypes.DOWNLOAD_DATA, undefined)
	}

	toggleHidden() {
		this.hidden = !this.hidden
		if (!this.hidden) {
			this.$emit('expand')
		}
	}

	forceHide() {
		this.hidden = true
	}

	getDescFor(field: string) {
		if (hoverDescriptions.hasOwnProperty(field)) {
			// @ts-ignore
			return hoverDescriptions[field]
		}
		return null
	}

	// Lifecycle hooks
	async mounted() {
		window.addEventListener('resize', e => {
			// @ts-ignore
			const newWidth = e?.target?.innerWidth
			if (newWidth < 900 && this.lastWidth > 900) {
				this.hidden = true
			}
			this.lastWidth = newWidth
		})
		this.$tStore.dispatch(ActionTypes.GET_DATA, undefined)
		this.$tStore.dispatch(ActionTypes.GET_GEODATA, undefined)

		// This can be used to audit the data
		// for (let res of Object.keys(s.resolutions) as s.ResolutionType[]) {
		// 	// We do resolutions first, since this will trigger a fetch of the geo data
		// 	this.resolution = res
		// 	for (let ind of Object.keys(this.indicators) as s.IndicatorType[]) {
		// 		this.indicator = ind
		// 		for (let vrt of Object.keys(this.variants) as s.VariantType[]) {
		// 			this.variant = vrt
		// 			for (let met of Object.keys(this.metrics) as s.MetricType[]) {
		// 				this.metric = met
		// 				for (let emi of Object.keys(this.emissions) as s.EmissionType[]) {
		// 					this.emission = emi
		// 					for (let str of Object.keys(this.strands) as s.StrandType[]) {
		// 						this.strand = str
		// 						await this.$tStore.dispatch(ActionTypes.GET_DATA, undefined)
		// 					}
		// 				}
		// 			}
		// 		}
		// 	}
		// }
	}
}
</script>

<style lang="scss">
@media (min-width: 900px) {
	.md-select-menu {
		left: 262px !important;
		width: 310px !important;
		max-width: 310px !important;
	}

	.md-menu.md-select i.md-icon svg {
		transform: rotate(270deg);
	}
}
</style>

<style scoped lang="scss">
@import '@/assets/scss/climpolVars.scss';

.control-panel {
	padding: 0;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	background-color: $mainBgColor;

	.scrollable {
		width: 320px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		overflow-y: auto;
		overflow-x: hidden;
		overflow-x: visible;
		padding: 0 16px;

		.md-field,
		.md-checkbox,
		.md-switch {
			margin-top: 8px;
			margin-bottom: 8px;
		}

		.md-button {
			margin-left: 8px;
			min-height: 36px;
		}
		.scenario-group {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;

			label.title {
				font-size: 14px;
				color: $dark;
			}

			&.active {
				border: 1px solid #aaaaaa;
				border-radius: 8px;
				padding: 4px;
			}
		}
	}

	.showhide {
		display: none;
	}
}

@media (max-width: 900px) {
	.control-panel {
		border-top-right-radius: 16px;
		border-bottom-right-radius: 16px;
		overflow: hidden;
		.showhide {
			background-color: $mainBgColor;
			width: 40px;

			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			padding-top: 8px;
			border-top-right-radius: 16px;
			border-bottom-right-radius: 16px;
			border-left: 1px solid rgba(0, 0, 0, 0.25);
			cursor: pointer;

			svg {
				width: 24px;
				height: 24px;
			}

			&:hover {
				background-color: $dark;

				svg {
					color: $mainBgColor;
				}
			}
		}

		.scrollable {
			width: calc(100vw - 84px);
			opacity: 1;

			transition: width 500ms ease-in, opacity 100ms ease-in 500ms;
			&.hidden {
				width: 0;
				padding: 0;
				opacity: 0;
				transition: opacity 100ms ease-out, width 500ms ease-out 100ms;
			}
		}
	}
}

@media (max-width: 400px) {
	.control-panel {
		.scrollable {
			.md-button {
				font-size: 13px;
			}
		}
	}
}
</style>
