import { GetterTree } from 'vuex'
import { State } from '@/store/state'

export type Getters = {
	loading(state: State): boolean
}

export const getters: GetterTree<State, State> & Getters = {
	loading: state => {
		return state.loadingCount > 0
	}
}
