import { Datum } from '@/store/state'
import { inflate } from 'pako'
import { ActionContext, ActionTree } from 'vuex'

import {
	emissions,
	indicators,
	members,
	metrics,
	resolutions,
	State,
	strands,
	variants,
} from '@/store/state'
import Papa from 'papaparse'
import { Mutations, MutationTypes } from './mutations'

import Plausible from 'plausible-tracker'

const { trackEvent } = Plausible({
	domain: 'uk-cri.org',
})

type AugmentedActionContext = {
	commit<K extends keyof Mutations>(
		key: K,
		payload: Parameters<Mutations[K]>[1]
	): ReturnType<Mutations[K]>
} & Omit<ActionContext<State, State>, 'commit'>

export enum ActionTypes {
	DOWNLOAD_DATA = 'DOWNLOAD_DATA',
	GET_DATA = 'GET_DATA',
	GET_COMPARE_DATA = 'GET_COMPARE_DATA',
	GET_GEODATA = 'GET_GEODATA',
}

export interface Actions {
	[ActionTypes.DOWNLOAD_DATA]({ commit }: AugmentedActionContext): void
	[ActionTypes.GET_DATA]({ commit }: AugmentedActionContext): void
	[ActionTypes.GET_COMPARE_DATA]({ commit }: AugmentedActionContext): void
	[ActionTypes.GET_GEODATA]({ commit }: AugmentedActionContext): void
}

let getting: string[] = []

async function getData(
	commit: <K extends keyof Mutations<State>>(
		key: K,
		payload: Parameters<Mutations<State>[K]>[1]
	) => ReturnType<Mutations[K]>,
	path: string,
	action: MutationTypes
) {
	if (getting.includes(path)) {
		return
	} else {
		getting.push(path)
	}
	const rawData = await fetch(path + '.gz')
	const unzipped = inflate(await rawData.arrayBuffer())
	const text = new TextDecoder('utf-8').decode(unzipped)
	const parsed = await Papa.parse<Datum>(text, {
		delimiter: ',',
		header: true,
		skipEmptyLines: true,
		// We want all fields to be numeric apart from location,
		// which should be forced to string
		dynamicTyping: (field: string | number) => field !== 'location',
		transformHeader: (header) => {
			const memberId = header.trim()
			if (memberId === 'year' || memberId === 'location') {
				return memberId
			}
			for (let member of Object.entries(members)) {
				if (memberId === member[1].id) {
					return member[0]
				}
			}
			// console.warn('Found unexpected member ID', memberId)
			return memberId
		},
	})
	if (
		!parsed.meta.fields?.includes('year') ||
		!parsed.meta.fields?.includes('location') ||
		!parsed.meta.fields?.includes('median')
	) {
		// We need to check the content of the data, because parser is
		// very permissable - even the 404.html will parse into *something*
		console.error('No valid data for:', path)
	} else {
		commit(action, parsed.data)
	}
	getting = getting.filter((p) => p !== path)
}

export const actions: ActionTree<State, State> & Actions = {
	async [ActionTypes.DOWNLOAD_DATA]({ commit, state }) {
		const link = document.createElement('a')
		const iid = indicators[state.indicator].id
		const vid = variants[state.variant].id
		const mid = metrics[state.metric].id
		const sid = strands[state.strand].id
		const eid = emissions[state.emission].id
		const rid = resolutions[state.resolution].id
		const dataFile = `${iid}_${vid}_${mid}_${sid}_${eid}_${rid}.csv`
		const path = `${__webpack_public_path__}data/${dataFile}`

		const rawData = await fetch(path + '.gz')
		const unzipped = inflate(await rawData.arrayBuffer())
		const blob = new Blob([unzipped], { type: 'text/csv' })

		link.href = URL.createObjectURL(blob)
		link.download = dataFile
		link.click()
		try {
			trackEvent('Download CSV')
		} catch {
			console.warn('Unable to use analytics')
		}
	},
	async [ActionTypes.GET_DATA]({ commit, state }) {
		commit(MutationTypes.SET_LOADING, undefined)

		const iid = indicators[state.indicator].id
		const vid = variants[state.variant].id
		const mid = metrics[state.metric].id
		const sid = strands[state.strand].id
		const eid = emissions[state.emission].id
		const rid = resolutions[state.resolution].id
		const dataFile = `${iid}_${vid}_${mid}_${sid}_${eid}_${rid}.csv`
		const path = `${__webpack_public_path__}data/${dataFile}`

		await getData(commit, path, MutationTypes.SET_DATA)

		commit(MutationTypes.SET_FINISHED_LOADING, undefined)
	},
	async [ActionTypes.GET_COMPARE_DATA]({ commit, state }) {
		if (!state.additionalScenario) {
			return
		}
		commit(MutationTypes.SET_LOADING, undefined)

		const iid = indicators[state.indicator].id
		const vid = variants[state.variant].id
		const mid = metrics[state.metric].id
		const sid = strands[state.additionalScenario.strand].id
		const eid = emissions[state.additionalScenario.emission].id
		const rid = resolutions[state.resolution].id
		const dataFile = `${iid}_${vid}_${mid}_${sid}_${eid}_${rid}.csv`
		const path = `${__webpack_public_path__}data/${dataFile}`

		await getData(commit, path, MutationTypes.SET_COMPARE_DATA)
		try {
			trackEvent('Load data for comparison')
		} catch {
			console.warn('Unable to use analytics')
		}

		commit(MutationTypes.SET_FINISHED_LOADING, undefined)
	},
	async [ActionTypes.GET_GEODATA]({ commit, state }) {
		commit(MutationTypes.SET_LOADING, undefined)
		const rawData = await fetch(
			`${__webpack_public_path__}geodata/${
				resolutions[state.resolution].id
			}.geojson`
		)
		const geoJson = await rawData.json()
		commit(MutationTypes.SET_GEODATA, geoJson)
		let spatialNames = []
		for (let feature of geoJson.features) {
			spatialNames.push({
				name: feature.properties.Name,
				// Ensure that the ids are strings, not numbers
				val: `${feature.properties.DataId}`,
			})
		}
		commit(MutationTypes.SET_SPATIAL_NAMES, spatialNames)
		commit(MutationTypes.SET_FINISHED_LOADING, undefined)
	},
}
