import Vue from 'vue'
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'

import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

import { Store, store } from '@/store/store'

import { VuePlausible } from 'vue-plausible'

Vue.use(VuePlausible, {
	domain: 'uk-cri.org',
})

import Highcharts from 'highcharts'
import VueHighcharts from 'highcharts-vue'
import highchartsMore from 'highcharts/highcharts-more'
import exporting from 'highcharts/modules/exporting'
import offlineExport from 'highcharts/modules/offline-exporting'

highchartsMore(Highcharts)
exporting(Highcharts)
offlineExport(Highcharts)
Vue.use(VueHighcharts, { Highcharts })

import '@/lib/fontawesome'

Vue.use(VueMaterial)

import { MdField } from 'vue-material/dist/components'

Vue.use(MdField)

Vue.component(
	'MdSelect',
	// @ts-ignore
	Vue.options.components.MdSelect.extend({
		methods: {
			// @ts-ignore
			isInvalidValue: function isInvalidValue() {
				// @ts-ignore
				return this.$el.validity
					? // @ts-ignore
					  this.$el.validity.badInput
					: // @ts-ignore
					  this.$el.querySelector('input').validity.badInput
			},
		},
	})
)

// Needs special treatment
const iconThing: any = L.Icon.Default.prototype
delete iconThing._getIconUrl

L.Icon.Default.mergeOptions({
	iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
	iconUrl: require('leaflet/dist/images/marker-icon.png'),
	shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

Vue.config.productionTip = false

declare module 'vue/types/vue' {
	interface Vue {
		$tStore: Store
	}
}

// Define typed store which points to vuex store but has correct type
Object.defineProperty(Vue.prototype, '$tStore', {
	get: function () {
		return this.$store as Store
	},
	enumerable: true,
})

import App from '@/App.vue'

new Vue({
	render: (h) => h(App),
	store,
}).$mount('#app')
