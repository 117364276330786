<template>
	<div id="app">
		<vue-simple-spinner
			v-if="loading"
			id="spinner"
			size="64"
			:line-size="8"
			line-bg-color="black"
			line-fg-color="#00ff88"
		></vue-simple-spinner>
		<div id="welcome" v-if="showWelcome">
			<div class="section section-1">
				<div class="bgimage"></div>
				<div class="header">
					<a href="https://www.reading.ac.uk/" target="_blank">
						<img src="@/assets/img/uor-logo-large.png" />
					</a>
					<a href="https://www.ukclimateresilience.org/" target="_blank">
						<img src="@/assets/img/crp-logo.svg" />
					</a>
					<a href="https://the-iea.org/" target="_blank">
						<img src="@/assets/img/iea-logo.png" />
					</a>
				</div>
				<h1>Welcome to the Climate Risk Indicator Explorer</h1>
				<p>
					Plot and access data on UK climate risks at scales from the local
					authority to the nation
				</p>
				<p>
					This website provides information on future changes to indicators of
					climate risk across the UK. The information is provided at scales
					ranging from the district to the four nations of the UK, and for
					several different scenarios describing how global emissions of the
					greenhouse gases that cause climate change might increase in the
					future.
				</p>
				<p>
					Users can plot maps showing the variation in indicators across the UK
					and can plot and download time series for specific locations.
				</p>
				<md-button class="md-raised md-primary" @click="showWelcome = false">
					Click to access the Risk Explorer
				</md-button>
				<label class="show-welcome top"
					>Show this welcome screen on start<input
						type="checkbox"
						v-model="showWelcomeOnStart"
				/></label>
			</div>
			<div class="section section-1a">
				<h2>What's new?</h2>
				<p>
					The website was updated in August 2023 with the following revisions:
				</p>
				<ul>
					<li>
						Replacement of indicators calculated using the probabilistic strand
						following updates in
						<a
							href="https://www.metoffice.gov.uk/research/approach/collaboration/ukcp/about/project-news"
							target="_blank"
							>August 2022 to the UKCP18 probabilistic projections</a
						>. The changes are relatively small.
					</li>
					<li>
						Addition of indicators calculated with the UKCP18 probabilistic
						RCP4.5 projections.
					</li>
					<li>
						Replacement of the "2, 3 and 4°C in 2100" indicators calculated from
						subsets of the probabilistic strand with indicators calculated from
						rescaled global strand projections, and addition of a "1.5°C in
						2100" scenario (see the info panel for more details).
					</li>
				</ul>
			</div>
			<div class="section section-2">
				<h2>Explore Climate Risk around the UK</h2>
				<p>
					With a wide range of configurable options, users can explore a vast
					range of climate indicators, with various metrics based on different
					climate scenarios, model strands, and at different spatial
					resolutions.
				</p>
				<img
					class="screenshot"
					src="@/assets/img/website.png"
					alt="Screenshot of the website in action"
				/>
				<md-button class="md-raised md-primary" @click="showWelcome = false">
					Click to access the risk explorer
				</md-button>
			</div>
			<div class="section section-3">
				<h2>Access the underlying data</h2>
				<p>
					All of the underlying data for this website can be downloaded using
					the "Download Data" button. This allows users to explore the data in
					their own software, or to use the data in their own research. Both the
					raw indicator data, and the visible map data can be downloaded. We use
					a Creative Commons license for the data, which means that the data can
					be used freely, so long as the source is acknowledged.
				</p>
			</div>
			<div class="section section-4">
				<h2>Detailed inline help</h2>
				<p>
					Full information about how to use the website, and what each field
					means, is available using the collapsible help panel on the right-hand
					side of the screen. If you would like to explore a detailed list of
					the indicators before using the website, you can do so by clicking
					<span class="link" @click="showIndicatorTable = true">here</span>.
				</p>
			</div>
			<div class="section section-4a">
				<h2>External video tutorials</h2>
				<p>
					The UK Climate Resilience Programme has produced a series of video
					tutorials on how to use the UK Climate Risk Explorer, including basic
					usage as well as how to make the most of the data and tailor the
					outputs to your own situation. These can be found
					<a
						href="https://www.ukclimateresilience.org/resources/e-learning/using-the-uk-climate-risk-indicators/"
						target="_blank"
						>on the main UK Climate Resilience website</a
					>.
				</p>
			</div>
			<div class="section section-5">
				<div class="bgimage field"></div>
				<h2>Compare different scenarios</h2>
				<p>
					By using the "Select additional scenario" and the "Select multiple
					regions" tools, users can compare different scenarios and models for a
					particular region, or can compare different regions with one another
					for a given scenario. Any plot can be downloaded as a PDF or as a PNG
					image, for use in presentations or reports.
				</p>
				<img
					class="screenshot"
					src="@/assets/img/multigraph.png"
					alt="Compare different scenarios"
				/>
				<md-button class="md-raised md-primary" @click="showWelcome = false">
					Click to access the risk explorer
				</md-button>
			</div>
			<div class="section section-5">
				<label class="show-welcome"
					>Show this welcome screen on start<input
						type="checkbox"
						v-model="showWelcomeOnStart"
				/></label>
			</div>
		</div>

		<control-panel
			id="control-panel"
			@expand="cpExpand"
			@geoJsonDownload="downloadGeoJson"
			ref="cp"
		></control-panel>
		<cri-header id="header"></cri-header>
		<cri-map id="map" ref="map"></cri-map>
		<cri-chart id="chart"></cri-chart>
		<info-panel
			id="info-panel"
			@resize="mapResize"
			@expand="ipExpand"
			@showIndicatorTable="showIndicatorTable = true"
			ref="ip"
		></info-panel>
		<cri-footer id="footer"></cri-footer>
		<indicator-table
			id="indicator-table"
			v-if="showIndicatorTable"
			@close="showIndicatorTable = false"
		></indicator-table>
		<div id="too-small">
			<p>
				We're sorry but this website does not work on a screen this small.
				Please resize your browser window or use a device with a larger screen.
			</p>
		</div>
	</div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import ControlPanel from '@/components/ControlPanel.vue'
import InfoPanel from '@/components/InfoPanel.vue'
import IndicatorTable from '@/components/IndicatorTable.vue'
import CriHeader from '@/components/Header.vue'
import CriFooter from '@/components/Footer.vue'
import CriMap from '@/components/Map.vue'
import CriChart from '@/components/Chart.vue'
import VueSimpleSpinner from 'vue-simple-spinner'
import Cookies from 'js-cookie'
import { MutationTypes } from './store/mutations'

const WELCOME_COOKIE = 'showWelcome'

@Component({
	components: {
		CriHeader,
		CriFooter,
		InfoPanel,
		IndicatorTable,
		ControlPanel,
		CriMap,
		CriChart,
		VueSimpleSpinner,
	},
})
export default class App extends Vue {
	showIndicatorTable: boolean = false

	get loading() {
		return this.$tStore.getters.loading
	}

	get showWelcome() {
		return this.$tStore.state.showWelcomePage
	}

	set showWelcome(val: boolean) {
		this.$tStore.commit(MutationTypes.SET_SHOW_WELCOME, val)
	}

	get showWelcomeOnStart() {
		const show = Cookies.get(WELCOME_COOKIE)
		return show === undefined || show === 'true'
	}

	set showWelcomeOnStart(val: boolean) {
		Cookies.set(WELCOME_COOKIE, val.toString())
	}

	mapResize() {
		this.$nextTick(() => {
			window.dispatchEvent(new Event('resize'))
		})
	}

	ipExpand() {
		// @ts-ignore
		this.$refs.cp.forceHide()
	}

	cpExpand() {
		// @ts-ignore
		this.$refs.ip.forceHide()
	}

	downloadGeoJson() {
		// @ts-ignore
		this.$refs?.map?.downloadGeoJson()
	}

	mounted() {
		this.$plausible.trackPageview()
		const show = Cookies.get(WELCOME_COOKIE)
		if ('false' === show) {
			this.$tStore.commit(MutationTypes.SET_SHOW_WELCOME, false)
		}
	}
}
</script>

<style lang="scss">
@import '@/assets/scss/climpol.scss';
@import '@/assets/scss/climpolVars.scss';

$headerHeight: 36px;
$footerHeight: 36px;

body {
	padding: 0;
	margin: 0;
}

.link {
	cursor: pointer;
	color: $dark;
}

#app {
	font-family: 'Avenir', Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	background-color: $mainBgColor;
	color: $textColor;
	width: 100vw;
	height: 100vh;
	margin: 0;
	padding: 0;
	display: grid;
	grid-template-columns: 320px minmax(240px, 1fr) auto;
	grid-template-rows: $headerHeight 2fr minmax(250px, 1fr) $footerHeight;
	grid-template-areas:
		'header header header'
		'control map info-panel'
		'control chart info-panel'
		'footer footer footer';

	#spinner {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 100;
		pointer-events: all;
		background: rgba(0, 0, 0, 0.25);
		width: 100%;
		height: 100%;
		padding-top: 40%;
	}

	#welcome {
		position: absolute;
		top: $headerHeight;
		left: 0;
		width: 100%;
		height: calc(100% - $headerHeight - $footerHeight);
		z-index: 20;
		overflow-y: auto;
		background-color: $mainBgColor;

		display: flex;
		flex-direction: column;

		h1 {
			margin-bottom: 0.5rem;
			font-size: 2rem;
			line-height: normal;
		}

		h2 {
			margin-top: 0.5rem;
			margin-bottom: 0.5rem;
			font-size: 1.8rem;
			line-height: normal;
		}

		.header {
			margin-top: 1rem;
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: flex-start;

			a img {
				width: 100px;
			}
		}

		.section {
			font-size: 1.25rem;
			position: relative;
			background-color: $lighter;
			padding: 1rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			line-height: 1.25;

			ul {
				text-align: left;
				margin-left: 2rem;

				li {
					margin-bottom: 0.5rem;
				}
			}

			img.screenshot {
				width: 80%;
				border: 2px solid $light;
				border-radius: 0.5rem;
				opacity: 0.9;
			}

			.bgimage {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				opacity: 0.15;
				background-image: url('@/assets/img/windfarm.jpg');
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
			}

			.field {
				background-image: url('@/assets/img/field.jpg');
			}
		}

		.section-4a,
		.section-1 {
			background-color: rgba(207, 225, 243, 0.5);
		}
		.section-1a,
		.section-3 {
			background-color: $dark;
			color: $mainBgColor;
			a {
				color: $lighter;

				&:hover {
					color: $light;
					text-decoration: none;
				}
			}
		}
		.section-4 {
			background-color: $light;
		}

		.show-welcome {
			align-self: flex-end;
			font-size: 0.9rem;
		}

		.show-welcome.top {
			font-size: 0.8rem;
			margin-bottom: -1rem;
			z-index: 100;
		}
	}

	#control-panel {
		z-index: 10;
		grid-area: control;
		box-shadow: 2px 2px 8px $medium;
	}

	#header {
		z-index: 5;
		grid-area: header;
	}

	#footer {
		z-index: 9;
		grid-area: footer;
		border-top: 4px solid $dark;
	}

	#map {
		z-index: 7;
		grid-area: map;
		border-top: 1px outset $light;
		border-bottom: 1px outset $light;
	}

	#chart {
		z-index: 5;
		grid-area: chart;
		height: 100%;
	}

	#info-panel {
		z-index: 10;
		grid-area: info-panel;
		box-shadow: -2px 2px 8px $medium;
	}

	#indicator-table {
		position: absolute;
		top: 8px;
		left: 8px;
		width: calc(100% - 16px);
		height: calc(100% - 16px);
		z-index: 50;
		border-radius: 16px;
		border: 1px solid $dark;
		box-shadow: 2px 2px 8px $medium;
	}

	#too-small {
		display: none;
	}
}
@media (max-width: 900px) {
	#app {
		grid-template-columns: minmax(350px, 1fr);
		grid-template-areas:
			'header'
			'map'
			'chart'
			'footer';

		#map,
		#chart {
			padding-left: 40px;
			padding-right: 40px;
		}
		#control-panel,
		#info-panel {
			position: absolute;
			top: $headerHeight;
			min-height: calc(100% - 72px);
			height: calc(100% - 72px);
		}
		#control-panel {
			left: 0;
		}
		#info-panel {
			right: 0;
		}
	}
}

@media (max-width: 360px) {
	#app {
		#spinner,
		#control-panel,
		#header,
		#footer,
		#map,
		#chart,
		#info-panel {
			display: none;
		}

		#too-small {
			background-color: $mainBgColor;
			padding: 32px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 100;

			p {
				padding: auto;
				vertical-align: middle;
			}
		}
	}
}
</style>
