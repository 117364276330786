// This defines static labels for everything.  In future, if required, it could
// be dynamically dependent on a language flag
import { IndicatorType } from '@/store/state'

const stringValues = <T>(lId: { [K in keyof T]: string }) => lId

export const labels = stringValues({
	category: 'Indicator Category',
	indicator: 'Indicator',
	variant: 'Variant',
	metric: 'Metric',
	strand: 'Strand',
	emission: 'Scenario',
	member: 'Member',
	resolution: 'Spatial Resolution',
	spatialUnit: 'Spatial Unit',
	timePeriod: 'Time Period',
	warmingLevel: 'Level of Warming',
	addRegions: 'Select Multiple Regions',
	addingRegions: 'Select Single Region',
	addScenarios: 'Select Additional Scenario',
	addingScenarios: 'Done',
	download: 'Download data',
	downloadMap: 'Download map data',
	tbd: '<PLACEHOLDER>',
	hoverInd: 'Indicator of climate risk',
	hoverVar: 'Particular definition of indicator',
	hoverMet: 'Units for the indicator',
	hoverScen: 'Climate scenario describing change over time or with level of warming ',
	hoverStrand: 'Set of UKCP18 climate projections',
	hoverMem: 'Which sample member from the strand to plot?',
	hoverRes: 'Gridded, local, regional or national scales',
	hoverSpU: 'Which location?',
	hoverTime: 'What 30-year time period?',
	hoverLevel: 'What level of warming above pre-industrial levels?'
})

export const hoverDescriptions = stringValues({
	meantemp: 'Change in seasonal average temperature',
	mintemp: 'Change in seasonal average daily minimum temperature',
	maxtemp: 'Change in seasonal average daily maximum temperature',
	recordtemp: 'Hottest and wettest days and months',
	rainfall: 'Change in seasonal total rainfall',
	heatwave: 'Heatwave as defined by the Met Office',
	heathealth:
		'Heat health alert used in health and social care emergency planning: amber alert level',
	cold: 'Alert used in health and social care emergency planning',
	hdd:
		'Accumulated degree-days below a threshold, used to index heating energy demands',
	cdd:
		'Accumulated degree-days above a threshold, used to index cooling energy demands',
	hotdays: 'Days with maximum temperature above 35°C',
	tropnights: 'Days with minimum temperature above 20°C',
	wbgt:
		'Wet Bulb Globe Temperature above 25°C: this combines temperature and humidity',
	roadmelt: 'Days with maximum temperature above 25°C',
	roadaccident: 'Days with minimum temperature below 0°C',
	railtemp: 'Days with maximum temperatures above specific thresholds',
	railops:
		'Days with temperature, rainfall or snowfall beyond specific thresholds used in the rail industry',
	sgs:
		'First day of the year when temperatures persistently exceed a growing threshold',
	gsl: 'Duration between first and last day of growing season',
	gdd: 'Accumulated degree-days above a threshold',
	cropduration: 'Time to accumulate a specific number of growing degree days',
	fieldops: 'Day of the year when accumulated temperatures exceed 200°C.',
	accfrost: 'Accumulated degree days below 0°C',
	frostdays: 'Days with minimum temperature below 0°C',
	milkyield: 'Days with temperature and humidity above a threshold',
	wheatheat:
		'Days with temperature above a threshold during the wheat flowering period',
	psmd:
		'Maximum difference between accumulated rainfall and potential evaporation',
	fcdays: 'Days with wet soils',
	spi:
		'Time in drought, defined from rainfall (Standardised Precipitation Index)',
	spei:
		'Time in drought, defined from rainfall  and potential evaporation (Standardised Precipitation Evaporation Index)',
	firedanger:
		'Days with Met Office wildfire index above thresholds used to manage open access',
	firedha:
		'Days with wildfire index above Daily Hazard Assessment warning thresholds',
	fireffmc:
		'Days with wildfire index above a value exceeded 99% of the time at present',
	runoff: 'Change in seasonal average river flow',
	flood: 'Change in size or likelihood of a flood',
	lowflow: 'Change in size or likelihood of low river flows',
	ssi12:
		'Time rivers are in drought, based on river flows accumulated over 12 months',
	ssi24:
		'Time rivers are in drought, based on river flows accumulated over 24 months',
	soilmoisture: 'Change in average seasonal soil moisture content',

	rcp85: 'Very high emissions, following the RCP8.5 emissions scenario',
	rcp60: 'High emissions, following the RCP6.0 emissions scenario',
	rcp26: 'Low emissions, following the RCP2.6 emissions scenario',
	deg2:
		'Scenario with global average temperature approximately 2°C above pre-industrial levels in 2100',
	deg3:
		'Scenario with global average temperature approximately 3°C above pre-industrial levels in 2100',
	deg4:
		'Scenario with global average temperature approximately 4°C above pre-industrial levels in 2100',
	warming: 'Indicators show against level of global warming, rather than time',

	hadGem: 'UKCP18 global strand projections, HadGEM climate model',
	cmip: 'UKCP18 global strand projections, CMIP5 climate models',
	regional: 'UKCP18 regional strand projections, HadGEM climate model',
	prob: 'UKCP18 probabilistic strand projections',

	min: 'Lowest sample in the ensemble of projections',
	min2: '2nd lowest sample in the ensemble of projections',
	median: 'Median value in the ensemble of projections',
	max2: '2nd highest sample in the ensemble of projections',
	max: 'Highest sample in the ensemble of projections',
	pc10: '10th percentile of samples in the ensemble',
	pc90: '90th percentile of samples in the ensemble',

	km12: 'Gridded data, on a 12x12km grid',
	lau: 'Smallest local authority area',
	nuts3: 'Counties and groups of districts or council areas',
	nuts2: 'Counties and groups of counties or council areas',
	lrf: 'Local Resilience Forum areas',
	region: 'UK government statistical regions',
	nation: 'England, Wales, Scotland and Northern Ireland'
})
