<template>
	<div class="info-panel">
		<div class="showhide" @click="toggleHidden">
			<font-awesome-icon icon="info-circle" />
			<font-awesome-icon
				:icon="hidden ? 'angle-double-left' : 'angle-double-right'"
			/>
		</div>
		<div class="content" :class="{ hidden }">
			<div class="tabs">
				<div
					class="tab"
					@click="panel = 'about'"
					:class="{ selected: panel === 'about' }"
				>
					About
				</div>
				<div
					class="tab"
					@click="panel = 'howto'"
					:class="{ selected: panel === 'howto' }"
				>
					How to
				</div>
				<div
					class="tab"
					@click="panel = 'moreinfo'"
					:class="{ selected: panel === 'moreinfo' }"
				>
					More Info
				</div>
			</div>
			<div class="panel" v-if="panel === 'about'">
				<h2>About</h2>
				<p>
					This website provides information on future changes to indicators of
					climate risk across the UK. The information is provided at scales
					ranging from the district to the four nations of the UK, and for
					several different scenarios describing how global emissions of the
					greenhouse gases that cause climate change might increase in the
					future.
				</p>
				<p>
					Users can plot maps showing the variation in indicators across the UK
					and can plot and download time series for specific locations.
				</p>
				<p>
					The research underpinning the data on this website was undertaken as
					part of the
					<a href="https://www.ukclimateresilience.org/" target="_blank">
						UK Climate Resilience Programme
					</a>
					funded by UK Research and Innovation and the Met Office. It uses the
					<a
						href="https://www.metoffice.gov.uk/research/approach/collaboration/ukcp/index"
						target="_blank"
					>
						UKCP18 climate projections
					</a>
					produced by the Met Office. The research was undertaken in the
					<a href="https://www.reading.ac.uk/met/" target="_blank">
						Department of Meteorology
					</a>
					at
					<a href="https://www.reading.ac.uk/" target="_blank">
						the University of Reading</a
					>, led by Professor Nigel Arnell, and at
					<a href="https://ceh.ac.uk/" target="_blank">
						the UK Centre for Ecology and Hydrology</a
					>. The website was developed by
					<a href="https://www.the-iea.org/" target="_blank">
						the Institute for Environmental Analytics
					</a>
					at the University of Reading. It is part of the University’s
					<a
						href="http://www.reading.ac.uk/research/research-environment/open-research.aspx"
						target="_blank"
					>
						commitment to Open Research.
					</a>
				</p>
				<p>
					Contact Professor Nigel Arnell (<a
						href="mailto:n.w.arnell@reading.ac.uk?subject=Climate%20Risk%20Indicators"
						target="_blank"
						>n.w.arnell@reading.ac.uk </a
					>) for more information.
				</p>
				<h3>Intended audience</h3>
				<p>
					The website is designed to allow users to estimate various indicators
					of current and future climate risk at local to national scales. It
					assumes some knowledge of climate change and climate scenarios, and
					further explanation is given under the More Info tab. The indicators
					that are included are limited to those calculated in the Climate
					Resilience Programme project: some of these indicators are very
					general, but others are much more specific and were selected as
					illustrative of a broader category of indicator.
				</p>
				<p>
					The website does not track users (other than monitoring traffic), and
					we would appreciate comments and feedback on uses.
				</p>
				<h3>Updates to this website</h3>
				<p>
					The website was updated in August 2023 with the following revisions:
				</p>
				<ol type="i">
					<li>
						Replacement of indicators calculated using the probabilistic strand
						following updates in
						<a
							href="https://www.metoffice.gov.uk/research/approach/collaboration/ukcp/about/project-news"
							target="_blank"
							>August 2022 to the UKCP18 probabilistic projections</a
						>. The changes are relatively small.
					</li>
					<li>
						Addition of indicators calculated with the UKCP18 probabilistic
						RCP4.5 projections.
					</li>
					<li>
						Replacement of the "2, 3 and 4°C in 2100" indicators calculated from
						subsets of the probabilistic strand with indicators calculated from
						rescaled global strand projections, and addition of a "1.5°C in
						2100" scenario (see the
						<span
							class="option clickable"
							@click="
								panel = 'moreinfo'
								toggleSection('scenarios', true)
							"
							>Scenarios</span
						>
						section for more details).
					</li>
				</ol>

				<h3>Licence</h3>
				<p>
					Data are provided under a
					<a rel="license" href="http://creativecommons.org/licenses/by/4.0/">
						Creative Commons Attribution 4.0 International License </a
					>. This means that the data can be used freely, with acknowledgement.
				</p>
				<p class="licence-image">
					<a rel="license" href="http://creativecommons.org/licenses/by/4.0/">
						<img
							alt="Creative Commons License"
							style="border-width: 0"
							src="https://i.creativecommons.org/l/by/4.0/88x31.png"
						/>
					</a>
				</p>
				<p>
					The University of Reading accepts no responsibility for the use of the
					data.
				</p>
			</div>
			<div class="panel" v-else-if="panel === 'howto'">
				<h2>How to...</h2>
				<p>
					The user can plot climate risk
					<span
						class="option clickable"
						@click="
							panel = 'moreinfo'
							toggleSection('indicators', true)
						"
						>indicators</span
					>, which may have several <span class="option">variants</span> (for
					example different seasons) and several possible
					<span class="option">metrics</span> (for example ‘days per year’ or ‘%
					chance’). The map shows the selected indicator for a specific time
					period or level of warming, and the graph shows the indicator at the
					selected location.
				</p>
				<p>
					The indicators are available for several
					<span
						class="option clickable"
						@click="
							panel = 'moreinfo'
							toggleSection('scenarios', true)
						"
						>scenarios</span
					>
					describing future climate with different rates of future emissions of
					greenhouse gases and at different levels of warming.
				</p>
				<p>
					Seven
					<span
						class="option clickable"
						@click="
							panel = 'moreinfo'
							toggleSection('resolutions', true)
						"
						>spatial resolutions</span
					>
					are available, ranging from a 12x12km grid to the four nations of the
					UK.
				</p>

				<div
					class="heading"
					@click="toggleHowToSection('indicator')"
					id="how-to-section-indicator"
				>
					<div class="line" />
					<h3>
						Selecting the indicator
						<font-awesome-icon
							:icon="howToSection === 'indicator' ? 'angle-up' : 'angle-down'"
						/>
					</h3>
					<div class="line" />
				</div>
				<div
					class="more-info-content"
					:class="{ expanded: howToSection === 'indicator' }"
				>
					<p>
						The indicators are grouped into categories. Select the category
						first, then select the indicator from the list that appears.
					</p>
					<p>
						Select the variant from the options provided in the drop down menu.
						Some indicators only have one option.
					</p>
					<p>
						Select the metric from the options provided. Some indicators only
						have one option.
					</p>
				</div>

				<div
					class="heading"
					@click="toggleHowToSection('scenario')"
					id="how-to-section-scenario"
				>
					<div class="line" />
					<h3>
						Selecting the scenario, strand, and member
						<font-awesome-icon
							:icon="howToSection === 'scenario' ? 'angle-up' : 'angle-down'"
						/>
					</h3>
					<div class="line" />
				</div>
				<div
					class="more-info-content"
					:class="{ expanded: howToSection === 'scenario' }"
				>
					<p>
						Select the scenario from the nine options provided. Four options are
						the RCP2.6, RCP4.5, RCP6.0 and RCP8.5 emissions scenarios used in
						the UKCP18 climate projections, representing low, medium-low, medium
						and very high emissions. Another four are scenarios reaching 1.5, 2,
						3 and 4°C above pre-industrial levels by 2100. The differences
						between these eight options represents the effect of uncertainty in
						future emissions of greenhouse gases.
					</p>
					<p>
						The ninth option is to present indicators by level of global warming
						above pre-industrial levels, without specifying when that level of
						warming occurs.
					</p>
					<p>
						The indicators are available for four
						<span
							class="option clickable"
							@click="
								panel = 'moreinfo'
								toggleSection('strands', true)
							"
							>strands</span
						>
						from the UKCP18 climate projections. There are two sets of Global
						strand projections (HadGEM3 and CMIP5) consisting of ensembles
						(samples) of 15 and 12 climate model projections respectively: the
						two ensembles can be very different, so have not been combined. The
						Regional strand consists of an ensemble of 12 regional HadGEM3
						projections. The Probabilistic Strand is based on the UKCP18
						probabilistic projections. The spread across the samples in a
						scenario and across strands for a given scenario gives an indication
						of the effect of effect of climate model uncertainty on the
						projected indicators. The Global and Regional strands span a smaller
						uncertainty range than the Probabilistic strand. Not all strands are
						available for all scenarios.
					</p>
					<p>
						Each strand consists of several members (or samples), but the
						numbers vary between strands. For the global and regional strands it
						is possible to map the median across all projections, the lowest,
						2<sup>nd</sup> lowest, 2<sup>nd</sup> highest or highest. It is also
						possible to plot individual members. The selected option is shown on
						the map, and highlighted in the graph. The graph also shows the
						range between the lowest and the highest member. For the
						probabilistic strand, it is only possible to map the median, 10<sup
							>th</sup
						>
						percentile or 90<sup>th</sup> percentile. The graph shows the range
						between the 10<sup>th</sup> and 90<sup>th</sup> percentiles.
					</p>
					<p>
						Mapping the median estimate gives a clear impression of the
						variation in indicator across the UK, but does not give an
						indication of the uncertainty range: this is shown in the graph. It
						is not necessarily the case that the highest values of one indicator
						would occur in the same future world as the highest values of
						another (for example big increases in floods and big increases in
						droughts), so exercise caution when comparing maps of lowest,
						highest or percentiles between different indicators. In order to
						build up a picture of changes in multiple indicators, compare maps
						of individual ensemble members.
					</p>
				</div>

				<div
					class="heading"
					@click="toggleHowToSection('resolution')"
					id="how-to-section-resolution"
				>
					<div class="line" />
					<h3>
						Selecting the resolution and location
						<font-awesome-icon
							:icon="howToSection === 'resolution' ? 'angle-up' : 'angle-down'"
						/>
					</h3>
					<div class="line" />
				</div>
				<div
					class="more-info-content"
					:class="{ expanded: howToSection === 'resolution' }"
				>
					<p>
						Select the required spatial resolution from the seven options. The
						12x12km option shows gridded data, but the others show data by
						different level of administrative unit. The ‘Local Authority Areas’
						level consists of all local authorities in the UK (it is level
						LAU1). ‘Counties/Districts’ consists of counties and some groups of
						districts (it is NUTS3), whilst ‘Counties’ consists of individual or
						groups of counties (NUTS2). ‘Local Resilience Forums’ are the areas
						used to coordinate emergency planning. ‘Region’ is the nine
						administrative regions of England, plus Wales, three regions in
						Scotland and Northern Ireland (and also the Isle of Man). ‘Nation’
						consists of England, Wales, Scotland and Northern Ireland.
					</p>
					<p>
						Select the specific place to plot on the graph from the ‘Spatial
						Unit’ drop-down menu.
					</p>
					<p>
						A place to plot can also be selected by clicking on the appropriate
						region on the map.
					</p>
				</div>

				<div
					class="heading"
					@click="toggleHowToSection('time')"
					id="how-to-section-time"
				>
					<div class="line" />
					<h3>
						Selecting the time period or level of warming
						<font-awesome-icon
							:icon="howToSection === 'time' ? 'angle-up' : 'angle-down'"
						/>
					</h3>
					<div class="line" />
				</div>
				<div
					class="more-info-content"
					:class="{ expanded: howToSection === 'time' }"
				>
					<p>
						The indicators are calculated over 30-year periods. If the scenario
						selected is an RCP or 2, 3 or 4 degrees in 2100, then select the
						Time Period to show on the map. If the scenario selected is ‘Warming
						level’ then select level of warming above pre-industrial levels.
					</p>
				</div>

				<div
					class="heading"
					@click="toggleHowToSection('additional')"
					id="how-to-section-additional"
				>
					<div class="line" />
					<h3>
						Select additional scenario
						<font-awesome-icon
							:icon="howToSection === 'additional' ? 'angle-up' : 'angle-down'"
						/>
					</h3>
					<div class="line" />
				</div>
				<div
					class="more-info-content"
					:class="{ expanded: howToSection === 'additional' }"
				>
					<p>
						This allows the user to plot a second scenario on the graph: it does
						not change the scenario plotted on the map.
					</p>
				</div>

				<div
					class="heading"
					@click="toggleHowToSection('multiple')"
					id="how-to-section-multiple"
				>
					<div class="line" />
					<h3>
						Select multiple regions
						<font-awesome-icon
							:icon="howToSection === 'multiple' ? 'angle-up' : 'angle-down'"
						/>
					</h3>
					<div class="line" />
				</div>
				<div
					class="more-info-content"
					:class="{ expanded: howToSection === 'multiple' }"
				>
					<p>
						If this option is selected, click on the map to add another region
						(from the same spatial resolution) to the chart. Click ‘select
						single region’ to return to showing just one region.
					</p>
				</div>

				<div
					class="heading"
					@click="toggleHowToSection('map')"
					id="how-to-section-map"
				>
					<div class="line" />
					<h3>
						Interpreting the map
						<font-awesome-icon
							:icon="howToSection === 'map' ? 'angle-up' : 'angle-down'"
						/>
					</h3>
					<div class="line" />
				</div>
				<div
					class="more-info-content"
					:class="{ expanded: howToSection === 'map' }"
				>
					<p>
						The map shows the variation in the selected indicator across the UK.
						The member selected and variant are shown in the colour legend. The
						default is to determine the limits for the colour scale from the
						selected scenario and strand that is plotted. Change this to ‘All
						members’ to define the limits from all members of that scenario and
						strand (this makes it easier to compare members). It is currently
						not possible to fix the limits across different scenarios or
						strands.
					</p>
					<p>
						The panel on the bottom right shows the value of the indicator for
						the selected spatial unit shown in the graph below.
					</p>
				</div>

				<div
					class="heading"
					@click="toggleHowToSection('graph')"
					id="how-to-section-graph"
				>
					<div class="line" />
					<h3>
						Interpreting the graph
						<font-awesome-icon
							:icon="howToSection === 'graph' ? 'angle-up' : 'angle-down'"
						/>
					</h3>
					<div class="line" />
				</div>
				<div
					class="more-info-content"
					:class="{ expanded: howToSection === 'graph' }"
				>
					<p>
						The graph shows the variation over time (or level of warming) for
						the selected indicator and location. Add another scenario, or
						another place, using the ‘Select Additional Scenario’ or ‘Select
						Multiple Regions’ options. The graph shows the selected member,
						together with the uncertainty range. For the global and regional
						strands this range is based on the lowest and highest members, and
						for the probabilistic strand is the range between the 10<sup
							>th</sup
						>
						and 90<sup>th</sup>
						percentiles.
					</p>
					<p>
						The graph shows values (magnitude or chance) calculated over 30-year
						periods, plotted at the mid-point of the 30-year period when
						emissions scenarios are selected. Hover over the graph to see
						specific values.
					</p>
					<p>
						The axis limits can be changed by clicking and dragging on the
						graph, or by pinching on a touchscreen device.
					</p>
					<p>The chart can be downloaded as a png, pdf or svg file.</p>
				</div>

				<div
					class="heading"
					@click="toggleHowToSection('download')"
					id="how-to-section-download"
				>
					<div class="line" />
					<h3>
						Downloading data
						<font-awesome-icon
							:icon="howToSection === 'download' ? 'angle-up' : 'angle-down'"
						/>
					</h3>
					<div class="line" />
				</div>
				<div
					class="more-info-content"
					:class="{ expanded: howToSection === 'download' }"
				>
					<p>
						Select <span class="option">download data</span> to download a csv
						file containing values for all locations (it is currently not
						possible to download just one location) for the selected indicator,
						scenario and strand. All data file names follow the format
						<em>indicator_variant_metric_strand_scenario_region.csv</em>. The
						codes for the filenames can be found
						<a href="/docs/download_file_names.xlsx">here</a>.
					</p>
					<p>
						The Global and Regional strand files all contain columns with year
						(or increase in temperature for level of warming), location, lowest,
						2<sup>nd</sup> lowest, median, 2<sup>nd</sup> highest, highest, and
						the individual ensemble members (15 for the Global HadGEM strand, 12
						for the others). The Probabilistic strand files contain just year,
						location, 10<sup>th</sup> percentile, median and 90<sup>th</sup>
						percentile. In each case, the year represents the central year of a
						30-year period.
					</p>
					<p>
						Codes for the locations/regions can be found
						<a href="/docs/location_codes.xlsx">here</a>.
					</p>
					<p>
						To download the data used to create the visible map, select
						<span class="option">download map</span> to download a GeoJSON file
						containing the definition of all of the visible regions, each with
						the attribute <code>Value</code>, containing the corresponding data
						value.
					</p>
				</div>
			</div>
			<div class="panel" v-else>
				<h2>More info</h2>
				<div
					class="heading"
					@click="toggleSection('indicators')"
					id="section-indicators"
				>
					<div class="line" />
					<h3>
						Indicators
						<font-awesome-icon
							:icon="section === 'indicators' ? 'angle-up' : 'angle-down'"
						/>
					</h3>
					<div class="line" />
				</div>
				<div
					class="more-info-content"
					:class="{ expanded: section === 'indicators' }"
				>
					<p>
						<span class="clickable" @click="$emit('showIndicatorTable')"
							>This table</span
						>
						summarises the definitions of the indicators, and the available
						variants and metrics. It also contains relevant caveats. All the
						indicators are calculated as averages or likelihoods over a 30-year
						period. With the exception of the water indicators, they are all
						calculated at a spatial resolution of 12x12km and presented as
						averages across the spatial resolution selected. Many of the
						indicators are weighted averages, with weighting by population,
						extent of transport network, or land use as appropriate. This is so
						that the regional average values are not influenced by parts of the
						region with low population totals, limited transport networks or no
						relevant land use.
					</p>
					<p>
						The water indicators were calculated by UKCEH using the Grid2Grid
						hydrological model which simulates river flows at all points along
						the river network in England, Scotland and Wales (not Northern
						Ireland). These indicators are only available at the national and
						regional scales, and were calculated by averaging values at all
						points within the region.
					</p>
					<p>
						The indicators that are included are limited to those calculated in
						the Climate Resilience Programme project: some of these indicators
						are very general, but others are much more specific and were
						selected as illustrative of a broader category of indicator.
					</p>
				</div>
				<div
					class="heading"
					@click="toggleSection('scenarios')"
					id="section-scenarios"
				>
					<div class="line" />
					<h3>
						Scenarios
						<font-awesome-icon
							:icon="section === 'scenarios' ? 'angle-up' : 'angle-down'"
						/>
					</h3>
					<div class="line" />
				</div>
				<div
					class="more-info-content"
					:class="{ expanded: section === 'scenarios' }"
				>
					<p>
						The period 1981-2010 is used to represent the current climate in all
						scenarios. For this period, the indicators are all calculated from
						observed weather data.
					</p>
					<p>
						The UKCP18 climate projections are based on scenarios describing
						possible future emissions of greenhouse gases. The scenarios used
						here are known as RCP2.6, RCP4.5, RCP6.0 and RCP8.5, representing
						low, medium-low, medium and very high emissions respectively (<a
							href="https://www.metoffice.gov.uk/research/approach/collaboration/ukcp/guidance-science-reports"
							target="_blank"
							>UKCP18 Overview Report</a
						>) . The numbers in the scenario names describe the extra radiative
						forcing to the atmosphere caused by increasing concentrations of
						greenhouse gases, and the scenarios are not intended to be
						predictions of future emissions: they are hypothetical scenarios
						used to run complex global climate models. RCP2.6 is consistent with
						sizeable reductions in emissions, and leads to an increase in global
						average temperature of around 2°C, relative to pre-industrial
						levels. RCP8.5 produces an increase in global average temperature by
						2100 of just over 5°C (range 4.6 to 6.0°C). Note that the
						<a
							href="https://climateactiontracker.org/global/cat-thermometer/"
							target="_blank"
							>Climate Action Tracker</a
						>
						estimates that current national climate policies would lead to an
						increase of around 2.9°C (2.1 to 3.9°C). RCP8.5 is therefore a very
						high scenario. See the guidance on the
						<a
							href="https://www.metoffice.gov.uk/research/approach/collaboration/ukcp/guidance-science-reports"
							target="_blank"
							>UKCP18 website</a
						>
						for recommendations on the use of RCP scenarios for risk assessment.
						This plot
						<img src="@/assets/img/ukcp18-probabilistic-subset.png" />
						shows the change in global average surface temperature with the four
						RCP scenarios for the probabilistic strand, and this plot
						<img src="@/assets/img/ukcp18-global-strand-subset.png" /> shows the
						change with the global HadGEM and CMIP5 RCP8.5 strands.
					</p>
					<p>
						Indicators are also shown here for four scenarios which are
						consistent with increases in global mean temperature by 2100 of 1.5,
						2, 3 and 4°C above pre-industrial levels. These scenarios were
						constructed by rescaling the UKCP18 global strand RCP8.5 members so
						that their global average surface temperature over the 21st century
						follows a temperature pathway consistent with an increase of 1.5, 2,
						3 or 4°C by 2100. There are several plausible pathways to each of
						these targets. Here, we use the
						<a
							href="https://www.ipcc.ch/report/ar6/wg1/chapter/summary-for-policymakers/"
							target="_blank"
							>IPCC Sixth Assessment Report</a
						>
						SSP1-1.9, SSP1-2.6, SSP2-45 and SSP3-70 pathways respectively to
						approximate 1.5, 2, 3 and 4°C worlds. These pathways are shown
						here:<img src="@/assets/img/ar6-subset.png" />. The uncertainty
						range for these scenarios represents just the uncertainty in
						projected local climate given that global average temperature
						follows a specific pathway. This uncertainty range is less than the
						uncertainty range for the RCP-based projections which also include
						uncertainty in the global average response to a specific emissions
						pathway.
					</p>
					<p>
						Indicators are also calculated for different specific levels of
						warming above pre-industrial levels, up to 4°C. The period 1981-2010
						was 0.61°C warmer than the pre-industrial average. The year that
						these levels of warming would be reached depends of course on the
						rate of emissions.
					</p>
				</div>
				<div
					class="heading"
					@click="toggleSection('strands')"
					id="section-strands"
				>
					<div class="line" />
					<h3>
						Strands
						<font-awesome-icon
							:icon="section === 'strands' ? 'angle-up' : 'angle-down'"
						/>
					</h3>
					<div class="line" />
				</div>
				<div
					class="more-info-content"
					:class="{ expanded: section === 'strands' }"
				>
					<p>
						The UKCP18 land projections have four strands: global, regional,
						local and probabilistic. The global, regional and local strands are
						ensembles of simulations from individual models, whilst the
						probabilistic strand consists of many thousands of scenarios derived
						using statistical relationships based on climate models. The global,
						regional and local strands are based on climate models with
						increasingly fine spatial resolutions, and are only available for
						the RCP8.5 emissions scenario (and RCP2.6 for the global strand).
						The global strand consists of two separate ensembles, one based on
						15 variants of the Met Office HadGEM3.05 climate model, and the
						other based on 12 other climate models (the CMIP5 ensemble). The two
						ensembles are quite different to each other (the HadGEM ensemble is
						typically warmer and drier in summer than the CMIP5 ensemble), so
						cannot be combined. The regional strand is based on higher
						resolution versions of 12 of the HadGEM3.05 variants, and the local
						strand is based on even higher resolution versions. Indicators have
						not been calculated here for the local strand. Each member of the
						global and regional strands is assumed to be equally plausible. The
						scenarios describing worlds reaching 1.5, 2, 3 or 4°C by 2100 are
						constructed from the global strand projections.
					</p>
					<p>
						The two global strands are used to calculate indicators at different
						levels of warming. Only 5 of the 12 CMIP5 models reached an increase
						of over 4°C, so only this subset of 5 is used in the global CMIP5
						"levels of warming" indicators.
					</p>
					<p>
						The probabilistic strand consists of 3000 scenarios for each
						emissions scenario, and spans a wider range than the global or
						regional strands which are based on subsets of the
						potentially-available climate models. However, whilst the individual
						global and regional strand models are consistent across the UK and
						across different weather variables, the individual probabilistic
						scenarios do not show such coherence and results are shown here just
						for percentiles from the probability distributions.
					</p>
					<p>
						For the global and regional strands, the user can plot the median,
						lowest, highest, second lowest or second highest, or can plot
						individual ensemble members. For the probabilistic strand, the user
						can plot the median plus some specific percentiles. Note that the
						median, extremes and percentiles are not necessarily from the same
						ensemble member in different regions, so a map of median or extremes
						does not necessarily define a consistent scenario across the UK. A
						map of an individual ensemble member, however, does represent a
						spatially-coherent scenario.
					</p>
				</div>
				<div
					class="heading"
					@click="toggleSection('resolutions')"
					id="section-resolutions"
				>
					<div class="line" />
					<h3>
						Spatial Resolutions
						<font-awesome-icon
							:icon="section === 'resolutions' ? 'angle-up' : 'angle-down'"
						/>
					</h3>
					<div class="line" />
				</div>
				<div
					class="more-info-content"
					:class="{ expanded: section === 'resolutions' }"
				>
					<p>
						Results are presented at seven spatial resolutions. The raw
						calculations were undertaken at a 12x12km resolution across the UK.
						Indicators were then averaged to increasingly coarse resolutions,
						weighted as described in
						<span class="option clickable" @click="toggleSection('indicators')"
							>Indicators</span
						>. The coarsest resolution is the four nations of the UK - England,
						Wales, Scotland and Northern Ireland
					</p>
					<p>
						The next coarsest resolution is the 10 English regions, Wales,
						Northern Ireland, three Scottish regions and the Isle of Man.
					</p>
					<p>
						‘Counties’ is the
						<a
							href="https://www.ons.gov.uk/methodology/geography/ukgeographies/eurostat"
							target="_blank"
							>NUTS 2 level of statistical region</a
						>, consisting of counties in England (most grouped), groups of
						districts in Greater London, groups of unitary authorities in Wales,
						groups of council areas in Scotland, and the whole of Northern
						Ireland.
					</p>
					<p>
						‘Counties/districts’ is the NUTS 3 level of statistical region,
						consisting of counties and groups of unitary authorities, districts
						and council areas.
					</p>
					<p>
						Local Resilience Forums (LRFs) are multi-agency partnerships
						concerned with planning for emergencies. They are mostly based on
						police authority boundaries.
					</p>
					<p>
						‘Local Authority Areas’ is the LAU1 level of statistical region,
						consisting of the most local layer of local government. Note that
						these are sometimes the same as the NUTS3 level.
					</p>
				</div>
				<div
					class="heading"
					@click="toggleSection('method')"
					id="section-method"
				>
					<div class="line" />
					<h3>
						Overview of method
						<font-awesome-icon
							:icon="section === 'method' ? 'angle-up' : 'angle-down'"
						/>
					</h3>
					<div class="line" />
				</div>
				<div
					class="more-info-content"
					:class="{ expanded: section === 'method' }"
				>
					<p>
						The indicators are calculated from daily weather data from 1981 to
						2100 at a spatial resolution of 12x12km. The time series of daily
						weather at each grid cell were calculated by applying climate change
						scenarios to observed daily data from the period 1981-2010. The
						observed daily data were taken from the HadUK-Grid observational
						data set (<a
							href="https://rmets.onlinelibrary.wiley.com/doi/full/10.1002/gdj3.78"
							target="_blank"
							>Hollis et al., 2019</a
						>), supplemented with ERA5 reanalysis.
					</p>
					<p>
						Projected daily weather (temperature, rainfall, windspeed, humidity
						and net radiation) from 2011 to 2100 were constructed by applying
						changes in climate from the UKCP18 climate projections to the
						observed daily data using a transient delta method. See
						<a
							href="https://www.sciencedirect.com/science/article/pii/S2212096320300553"
							target="_blank"
							>Arnell et al. (2021)</a
						>
						for more details on the method.
					</p>
					<p>
						The period 1981 to 2010 is therefore the same for all scenarios, and
						is based on observations.
					</p>
					<p class="reference">
						<a
							href="https://www.sciencedirect.com/science/article/pii/S2212096320300553"
							target="_blank"
						>
							<span class="authors">
								Arnell, N.W., Kay, A.L., Freeman, A., Rudd, A.C. & Lowe, J.A.
							</span>
							<span class="year">(2021a) </span>
							<span class="title">
								Changing climate risk in the UK: a multi-sectoral analysis using
								policy-relevant indicators.
							</span>
							<span class="journal">Climate Risk Management </span>
							<span class="pages">31, 100265 </span>
							<span class="doi">10.1016/j.crm.2020.100265</span>
						</a>
					</p>
					<p class="reference">
						<a
							href="https://rmets.onlinelibrary.wiley.com/doi/full/10.1002/gdj3.78"
							target="_blank"
						>
							<span class="authors">Hollis, D. et al. </span>
							<span class="year">(2019) </span>
							<span class="title">
								HadUK-Grid – a new UK dataset of gridded climate observations.
							</span>
							<span class="journal">Geosciences Data Journal </span>
							<span class="pages">6, 151-159.</span>
						</a>
					</p>
				</div>

				<div class="heading" @click="toggleSection('tips')" id="section-tips">
					<div class="line" />
					<h3>
						Tips and suggestions
						<font-awesome-icon
							:icon="section === 'tips' ? 'angle-up' : 'angle-down'"
						/>
					</h3>
					<div class="line" />
				</div>
				<div
					class="more-info-content"
					:class="{ expanded: section === 'tips' }"
				>
					<p>
						The
						<a
							href="https://www.metoffice.gov.uk/research/approach/collaboration/ukcp/guidance-science-reports"
							target="_blank"
							>UKCP18 website</a
						>
						gives some recommendations on the use of scenarios for risk
						assessment, but there are currently no general formal guidelines
						because user requirements vary and different communities have
						adopted different guidelines. This website does not make formal
						recommendations, but provides information consistent with different
						guidelines.
					</p>
					<ul>
						<li>
							RCP8.5 represents very high emissions – above those currently
							projected globally – and can be interpreted as an upper or worst
							case.
						</li>
						<li>
							RCP2.6 can be interpreted as a ‘best case’: the 2 degree scenario
							is similar but with a narrower uncertainty range
						</li>
						<li>
							There is little difference in practice between the Global HadGEM
							RCP8.5 and Regional RCP8.5 projections, because the regional
							projections are based on (a subset of) the global models.
						</li>
						<li>
							The Global HadGEM and CMIP5 scenarios each span only a portion of
							the full uncertainty range.
						</li>
						<li>
							In order to compare the effect of different emissions scenarios or
							temperature targets on indicators, either (i) compare the four RCP
							scenarios, (ii) compare the 1.5, 2, 3 and 4 degree scenarios, or
							(iii) compare the Global HadGEM RCP8.5 and Global HadGEM RCP2.6.
						</li>
						<li>
							Build up a coherent narrative of multiple indicators in many
							places using the individual members from the Global or Regional
							strands. Each individual member is equally plausible.
						</li>
					</ul>
				</div>

				<div
					class="heading"
					@click="toggleSection('papers')"
					id="section-papers"
				>
					<div class="line" />
					<h3>
						Published papers
						<font-awesome-icon
							:icon="section === 'papers' ? 'angle-up' : 'angle-down'"
						/>
					</h3>
					<div class="line" />
				</div>
				<div
					class="more-info-content"
					:class="{ expanded: section === 'papers' }"
				>
					<p class="reference">
						<a
							href="https://www.sciencedirect.com/science/article/pii/S2212096320300553"
							target="_blank"
						>
							<span class="authors"
								>Arnell, N.W., Kay, A.L., Freeman, A., Rudd, A.C. & Lowe, J.A.
							</span>
							<span class="year">(2021) </span>
							<span class="title"
								>Changing climate risk in the UK: a multi-sectoral analysis
								using policy-relevant indicators.
							</span>
							<span class="journal">Climate Risk Management </span>
							<span class="pages">31, 100265 </span>
							<span class="doi">10.1016/j.crm.2020.100265 </span>
						</a>
					</p>
					<p class="reference">
						<a
							href="https://iopscience.iop.org/article/10.1088/1748-9326/abd9f2"
							target="_blank"
						>
							<span class="authors"
								>Arnell, N.W., Freeman, A., & Gazzard, R.
							</span>
							<span class="year">(2021) </span>
							<span class="title"
								>The effect of climate change on indicators of fire danger in
								the UK.
							</span>
							<span class="journal">Environmental Research Letters </span>
							<span class="pages">16, 044027 </span>
							<span class="doi">10.1088/1748-9326/abd9f2 </span>
						</a>
					</p>
					<p class="reference">
						<a
							href="https://link.springer.com/article/10.1007/s10584-021-03054-8"
							target="_blank"
						>
							<span class="authors">Arnell, N.W & Freeman, A. </span>
							<span class="year">(2021) </span>
							<span class="title"
								>The effect of climate change on agro-climatic indicators in the
								UK.
							</span>
							<span class="journal">Climatic Change </span>
							<span class="pages">165, 40. </span>
							<span class="doi">10.1007/s10584-021-0305408 </span>
						</a>
					</p>
					<p class="reference">
						<a
							href="https://rmets.onlinelibrary.wiley.com/doi/10.1002/cli2.12"
							target="_blank"
						>
							<span class="authors">Arnell, N.W. & Freeman, A. </span>
							<span class="year">(2021) </span>
							<span class="title"
								>The impact of climate change on policy-relevant indicators of
								temperature extremes in the United Kingdom.
							</span>
							<span class="journal">Climate Resilience and Sustainability</span>
							<span class="doi">10.1002/cli2.12 </span>
						</a>
					</p>
					<p class="reference">
						<a
							href="https://iopscience.iop.org/article/10.1088/2515-7620/ac24c0/meta"
							target="_blank"
						>
							<span class="authors"
								>Arnell, N.W., Kay, A.L., Freeman, A., Rudd, A.C. & Lowe,
								J.A.</span
							>
							<span class="year">(2021) </span>
							<span class="title">
								Indicators of climate risk in the UK at different levels of
								warming.
							</span>
							<span class="journal">Environmental Research Communications</span>
							<span class="pages">3, 095005</span>
						</a>
					</p>
					<p class="reference">
						<a
							href="https://www.sciencedirect.com/science/article/pii/S0309170821000646"
							target="_blank"
						>
							<span class="authors"
								>Kay, A.L., Griffin, A., Rudd, A.C., Chapman, R.M., Bell, V.A. &
								Arnell, N.W.
							</span>
							<span class="year">(2021) </span>
							<span class="title">
								Climate change effects on indicators of high and low river flows
								across Great Britain.
							</span>
							<span class="journal">Advances in Water Resources</span>
							<span class="pages">151, 103909</span>
							<span class="doi">10.1016/j.advwatres.2021.103909</span>
						</a>
					</p>
				</div>

				<div
					class="heading"
					@click="toggleSection('glossary')"
					id="section-glossary"
				>
					<div class="line" />
					<h3>
						Acronyms and glossary
						<font-awesome-icon
							:icon="section === 'glossary' ? 'angle-up' : 'angle-down'"
						/>
					</h3>
					<div class="line" />
				</div>
				<div
					class="more-info-content"
					:class="{ expanded: section === 'glossary' }"
				>
					<table class="glossary">
						<tr>
							<td class="acronym">CMIP5</td>
							<td class="explanation">
								Coupled Model Intercomparison Project 5 (a set of climate
								models)
							</td>
						</tr>
						<tr>
							<td class="acronym">HadGEM3</td>
							<td class="explanation">
								Hadley Centre Global Environment Model (a climate model)
							</td>
						</tr>
						<tr>
							<td class="acronym">Indicator</td>
							<td class="explanation">
								A quantitative measure of some aspect of climate
							</td>
						</tr>
						<tr>
							<td class="acronym">LAU1</td>
							<td class="explanation">Local Administrative Units Level 1</td>
						</tr>
						<tr>
							<td class="acronym">Level of warming</td>
							<td class="explanation">
								Increase in global average temperature above pre-industrial
								levels
							</td>
						</tr>
						<tr>
							<td class="acronym">LRF</td>
							<td class="explanation">Local Resilience Forum</td>
						</tr>
						<tr>
							<td class="acronym">Metric</td>
							<td class="explanation">Units of an indicator</td>
						</tr>
						<tr>
							<td class="acronym">NUTS2</td>
							<td class="explanation">
								Counties and groups of counties, unitary authorities and council
								areas
							</td>
						</tr>
						<tr>
							<td class="acronym">NUTS3</td>
							<td class="explanation">
								Counties, unitary authorities and districts in England (some
								grouped), groups of unitary authorities in Wales, council areas
								in Scotland and districts in Northern Ireland
							</td>
						</tr>
						<tr>
							<td class="acronym">Pre-industrial</td>
							<td class="explanation">
								The reference period for levels of warming: assumed to be
								1850-1900
							</td>
						</tr>
						<tr>
							<td class="acronym">RCP</td>
							<td class="explanation">
								Representative Concentration Pathway (emissions scenario). The
								numbers refer to the effect of increased emissions on the
								earth’s energy balance by 2100 (RCP8.5 therefore means an
								additional 8.5 W/m<sup>2</sup> of radiative forcing due to
								greenhouse gases)
							</td>
						</tr>
						<tr>
							<td class="acronym">Scenario</td>
							<td class="explanation">
								A projection of future emissions, either following assumed
								future emissions or consistent with a specific increase in
								global average temperature
							</td>
						</tr>
						<tr>
							<td class="acronym">Strand</td>
							<td class="explanation">
								A set of projections for a specific climate scenario
							</td>
						</tr>
						<tr>
							<td class="acronym">Tmin</td>
							<td class="explanation">Minimum temperature</td>
						</tr>
						<tr>
							<td class="acronym">Tmax</td>
							<td class="explanation">Maximum temperature</td>
						</tr>
						<tr>
							<td class="acronym">UKCP18</td>
							<td class="explanation">UK Climate Projections 2018</td>
						</tr>
						<tr>
							<td class="acronym">Variant</td>
							<td class="explanation">
								Sub-type of an Indicator (for example a season)
							</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'

@Component({
	name: 'About',
})
export default class About extends Vue {
	panel: 'about' | 'howto' | 'moreinfo' = 'about'
	hidden: boolean = true //window.innerWidth < 900
	lastWidth: number = window.innerWidth

	howToSection:
		| 'indicator'
		| 'scenario'
		| 'resolution'
		| 'time'
		| 'additional'
		| 'multiple'
		| 'map'
		| 'graph'
		| 'download'
		| null = 'indicator'

	section:
		| 'indicators'
		| 'scenarios'
		| 'strands'
		| 'resolutions'
		| 'method'
		| 'tips'
		| 'papers'
		| 'glossary'
		| null = 'indicators'

	toggleHidden() {
		this.hidden = !this.hidden
		if (!this.hidden) {
			this.$emit('expand')
		}
		setTimeout(() => this.$emit('resize'), 600)
	}

	forceHide() {
		this.hidden = true
	}

	toggleHowToSection(
		s:
			| 'indicator'
			| 'scenario'
			| 'resolution'
			| 'time'
			| 'additional'
			| 'multiple'
			| 'map'
			| 'graph'
			| 'download'
			| null,
		force: boolean = false
	) {
		this.howToSection = this.howToSection === s && !force ? null : s
		this.$nextTick(() => {
			document
				.getElementById(`how-to-section-${s}`)
				?.scrollIntoView({ block: 'start', behavior: 'smooth' })
		})
	}

	toggleSection(
		s:
			| 'indicators'
			| 'scenarios'
			| 'strands'
			| 'resolutions'
			| 'method'
			| 'tips'
			| 'papers'
			| 'glossary'
			| null,
		force: boolean = false
	) {
		this.section = this.section === s && !force ? null : s
		this.$nextTick(() => {
			document
				.getElementById(`section-${s}`)
				?.scrollIntoView({ block: 'start', behavior: 'smooth' })
		})
	}

	mounted() {
		window.addEventListener('resize', (e) => {
			// @ts-ignore
			const newWidth = e?.target?.innerWidth
			if (newWidth < 900 && this.lastWidth >= 900) {
				this.hidden = true
			} else if (this.lastWidth < 900 && newWidth >= 900) {
				this.hidden = false
			}
			this.lastWidth = newWidth
		})
	}
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/climpolVars.scss';

.info-panel {
	overflow: hidden;

	color: $textColor;
	background-color: $mainBgColor;
	display: flex;

	border-bottom-left-radius: 16px;
	border-top-left-radius: 16px;

	.showhide {
		width: 40px;
		background-color: $mainBgColor;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		padding-top: 8px;
		border-right: 1px solid rgba(0, 0, 0, 0.25);
		cursor: pointer;

		svg {
			width: 24px;
			height: 24px;
		}

		&:hover {
			background-color: $dark;

			svg {
				color: $mainBgColor;
			}
		}
	}

	.content {
		width: 320px;
		opacity: 1;
		transition: width 500ms ease-in, opacity 100ms ease-in 500ms;
		&.hidden {
			width: 0;
			opacity: 0;
			transition: opacity 100ms ease-out, width 500ms ease-out 100ms;
		}

		display: flex;
		flex-direction: column;

		.tabs {
			display: flex;

			.tab {
				flex: 1 1 100%;
				cursor: pointer;
				border-right: 1px solid $textColor;
				border-bottom: 1px solid $textColor;
				background-color: $dark;
				color: $mainBgColor;

				&:last-child {
					border-right: none;
				}
				&.selected {
					border-bottom: none;
					background-color: $mainBgColor;
					color: $textColor;
				}
			}
		}

		.panel {
			padding: 8px;
			overflow-y: auto;

			ol,
			ul {
				padding-left: 16px;
				li {
					text-align: start;
					margin-bottom: 4px;
				}
			}

			table {
				tr:nth-child(even) {
					background-color: $tabColor;
				}
				tr:nth-child(odd) {
					background-color: $lighter;
				}

				td {
					text-align: left;
					padding: 2px;
				}
			}

			li,
			p {
				text-align: start;

				span.option {
					font-weight: bold;
				}

				span.clickable {
					cursor: pointer;
					color: $dark;
				}

				&.licence-image {
					text-align: center;
					margin-block-start: 4px;
					margin-block-end: 4px;
				}
			}

			.heading {
				cursor: pointer;
				display: flex;
				align-items: center;
				.line {
					background-color: red;
					border-bottom: 2px groove $light;
					flex-grow: 1;
					flex-basis: 12px;
					height: 0;
				}

				h3 {
					margin: 0 8px;
				}
			}

			.more-info-content {
				height: 0;
				opacity: 0;
				overflow: hidden;
				transition: height 500ms ease-in, opacity 100ms ease-in 500ms;
				margin-bottom: 16px;

				&.expanded {
					height: auto;
					opacity: 1;
					transition: opacity 100ms ease-out, height 500ms ease-out 100ms;
				}

				.reference {
					font-size: 13px;
				}
			}

			.reference {
				.authors {
					font-weight: bold;
				}
				.year {
				}
				.title {
					font-style: italic;
				}
				.journal {
				}
				.pages {
				}
				.doi {
				}
			}
		}
	}
}

@media (max-width: 900px) {
	.info-panel {
		.content {
			width: calc(100vw - 84px);
		}
	}
}
</style>
