<template>
	<div class="header">
		<h1>Climate Risk Indicators</h1>
		<md-button v-if="!showWelcome" @click="showWelcome = true">
			Show Welcome page
		</md-button>
	</div>
</template>

<script lang="ts">
import { MutationTypes } from '@/store/mutations'
import { Vue, Component } from 'vue-property-decorator'

@Component({
	name: 'Header'
})
export default class Header extends Vue {
	mounted() {}

	get showWelcome() {
		return this.$tStore.state.showWelcomePage
	}

	set showWelcome(val: boolean) {
		this.$tStore.commit(MutationTypes.SET_SHOW_WELCOME, val)
	}
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/climpolVars.scss';

.header {
	background-color: $dark;

	display: flex;
	justify-content: flex-start;
	h1 {
		font-size: 32px;
		font-weight: 200;
		color: $mainBgColor;
		margin-top: 8px;
		margin-left: 8px;
		height: 100%;
	}

	button {
		margin: 0;
		margin-left: auto;
		// background-color: $medium;
		border: none;
		color: $mainBgColor;
		cursor: pointer;
	}
}

@media (max-width: 380px) {
	.header {
		h1 {
			font-size: 24px;
		}
	}
}
</style>
