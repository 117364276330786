import { ScaleLinear } from 'd3-scale'
import { interpolateBrBG, interpolatePlasma } from 'd3-scale-chromatic'
import { GeoJSON } from 'leaflet'

interface LabelledIndicator {
	id: string
	name: string
	scale?: typeof interpolatePlasma
	symmetrical?: boolean
}

interface LabelledId {
	id: string
	name: string
}

interface LabelledNumericId {
	id: number
	name: string
}

const asLabelledIndicator = <T>(lId: { [K in keyof T]: LabelledIndicator }) =>
	lId
const asLabelledId = <T>(lId: { [K in keyof T]: LabelledId }) => lId
const asLabelledNumericId = <T>(lId: { [K in keyof T]: LabelledNumericId }) =>
	lId

export const categories = asLabelledId({
	climate: {
		id: 'climate',
		name: 'Climate'
	},
	temp: {
		id: 'temp',
		name: 'Temperature extremes'
	},
	heatcool: {
		id: 'heatcool',
		name: 'Heating and cooling'
	},
	transport: {
		id: 'transport',
		name: 'Transport'
	},
	agri: {
		id: 'agri',
		name: 'Agriculture'
	},
	fire: {
		id: 'fire',
		name: 'Wildfire'
	},
	water: {
		id: 'water',
		name: 'Water'
	}
})

export const indicators = asLabelledIndicator({
	meantemp: {
		id: 'tavg',
		name: 'Average temperature'
	},
	mintemp: {
		id: 'tmin',
		name: 'Minimum temperature'
	},
	maxtemp: {
		id: 'tmax',
		name: 'Maximum temperature'
	},
	recordtemp: {
		id: 'record',
		name: 'Record-breaking weather'
	},
	rainfall: {
		id: 'rain',
		name: 'Rainfall',
		scale: interpolateBrBG,
		symmetrical: true
	},
	heatwave: {
		id: 'mohw',
		name: 'Met Office heatwave'
	},
	heathealth: {
		id: 'heathealth',
		name: 'Amber heat-health alert'
	},
	cold: {
		id: 'coldweather',
		name: 'Cold weather alert'
	},
	hotdays: {
		id: 'hotdays',
		name: 'Very hot days (Tmax > 35°C)'
	},
	tropnights: {
		id: 'tropnights',
		name: 'Tropical nights (Tmin > 20°C)'
	},
	wbgt: {
		id: 'wbgt25',
		name: 'Heat stress (WBGT > 25°C)'
	},
	hdd: {
		id: 'hdd',
		name: 'Heating Degree Days'
	},
	cdd: {
		id: 'cdd',
		name: 'Cooling Degree Days'
	},
	roadmelt: {
		id: 'roadmelt',
		name: 'Road melt risk (Tmax > 25°C)'
	},
	roadaccident: {
		id: 'roadaccident',
		name: 'Road accident risk (Tmin < 0°C)'
	},
	railtemp: {
		id: 'railtemp',
		name: 'Rail: high temperatures'
	},
	railops: {
		id: 'railops',
		name: 'Rail: adverse weather days'
	},
	sgs: {
		id: 'sgs',
		name: 'Start of crop growing season'
	},
	gsl: {
		id: 'gsl',
		name: 'Growing season length'
	},
	gdd: {
		id: 'gdd',
		name: 'Growing Degree Days'
	},
	cropduration: {
		id: 'cropduration',
		name: 'Crop growth duration'
	},
	fieldops: {
		id: 'tsum',
		name: 'Start of field operations (Tsum200)'
	},
	accfrost: {
		id: 'aff',
		name: 'Accumulated frost'
	},
	frostdays: {
		id: 'frost',
		name: 'Frost days (Tmin < 0°C)'
	},
	milkyield: {
		id: 'milkyield',
		name: 'Dairy cattle heat stress'
	},
	wheatheat: {
		id: 'wheatheat',
		name: 'Wheat heat stress'
	},
	psmd: {
		id: 'psmd',
		name: 'Potential Soil Moisture Deficit'
	},
	fcdays: {
		id: 'fcdays',
		name: 'Days above field capacity'
	},
	spi: {
		id: 'spi',
		name: 'SPI drought'
	},
	spei: {
		id: 'spei',
		name: 'SPEI drought'
	},
	firedanger: {
		id: 'mofsi',
		name: 'Met Office Fire Danger'
	},
	firedha: {
		id: 'firedha',
		name: 'Wildfire: Daily Hazard Assessment'
	},
	fireffmc: {
		id: 'fireffmc',
		name: 'Wildfire: FFMC 99th percentile'
	},
	runoff: {
		id: 'runoff',
		name: 'River runoff'
	},
	flood: {
		id: 'flood',
		name: 'River flood'
	},
	lowflow: {
		id: 'lowflow',
		name: 'Low river flows'
	},
	ssi12: {
		id: 'ssi12',
		name: '12-month river flow drought'
	},
	ssi24: {
		id: 'ssi24',
		name: '24-month river flow drought'
	},
	soilmoisture: {
		id: 'soilmoisture',
		name: 'Soil moisture'
	}
})

export const variants = asLabelledId({
	none: {
		id: 'null',
		name: 'N/A'
	},
	annual: {
		id: 'ann',
		name: 'Annual'
	},
	winter: {
		id: 'djf',
		name: 'Winter'
	},
	spring: {
		id: 'mam',
		name: 'Spring'
	},
	summer: {
		id: 'jja',
		name: 'Summer'
	},
	autumn: {
		id: 'son',
		name: 'Autumn'
	},
	hotday: {
		id: 'tday',
		name: 'Hottest day'
	},
	hotmonth: {
		id: 'tmon',
		name: 'Hottest month'
	},
	wetmonth: {
		id: 'rain',
		name: 'Wettest month'
	},
	d15: {
		id: '155',
		name: '15.5°C'
	},
	d18: {
		id: '18',
		name: '18°C'
	},
	d22: {
		id: '22',
		name: '22°C'
	},
	gt21: {
		id: 'gt21',
		name: '>21°C'
	},
	gt24: {
		id: 'gt24',
		name: '>24°C'
	},
	gt26: {
		id: 'gt26',
		name: '>26°C'
	},
	gt30: {
		id: 'gt30',
		name: '>30°C'
	},
	adverse: {
		id: 'adverse',
		name: 'Adverse'
	},
	extreme: {
		id: 'extreme',
		name: 'Extreme'
	},
	severe: {
		id: 'severe',
		name: 'Severe'
	},
	vhigh: {
		id: 'veryhigh',
		name: 'Very High'
	},
	exceptional: {
		id: 'exceptional',
		name: 'Exceptional'
	},
	yellow: {
		id: 'yellow',
		name: 'Yellow'
	},
	amber: {
		id: 'amber',
		name: 'Amber'
	},
	y2: {
		id: '2yr',
		name: '2-year'
	},
	y10: {
		id: '10yr',
		name: '10-year'
	},
	y30: {
		id: '30yr',
		name: '30-year'
	}
})

export const metrics = asLabelledId({
	deltatemp: {
		id: 'dc',
		name: '°C Change'
	},
	days: {
		id: 'dyr',
		name: 'days/year'
	},
	months: {
		id: 'myr',
		name: 'months/year'
	},
	percent: {
		id: 'pc',
		name: '% change'
	},
	events: {
		id: 'events',
		name: 'events/year'
	},
	duration: {
		id: 'duration',
		name: 'duration'
	},
	chance: {
		id: 'chance',
		name: '% chance'
	},
	wildfirechance: {
		id: 'chance',
		name: '% chance > mean'
	},
	dd: {
		id: 'dd',
		name: 'degree-days'
	},
	date: {
		id: 'date',
		name: 'day'
	},
	mm: {
		id: 'mm',
		name: 'mm'
	},
	prop: {
		id: 'prop',
		name: 'proportion of time'
	}
})

export const strands = asLabelledId({
	hadGem: {
		id: 'ghadgem',
		name: 'UKCP18 Global HadGEM'
	},
	cmip: {
		id: 'gcmip5',
		name: 'UKCP18 Global CMIP5'
	},
	regional: {
		id: 'regional',
		name: 'UKCP18 Regional'
	},
	prob: {
		id: 'prob',
		name: 'UKCP18 Probabilistic'
	}
})

export const emissions = asLabelledId({
	rcp85: {
		id: 'rcp85',
		name: 'RCP8.5'
	},
	rcp60: {
		id: 'rcp60',
		name: 'RCP6.0'
	},
	rcp45: {
		id: 'rcp45',
		name: 'RCP4.5'
	},
	rcp26: {
		id: 'rcp26',
		name: 'RCP2.6'
	},
	deg15: {
		id: '15degmodel',
		name: '1.5°C in 2100'
	},
	deg2: {
		id: '2degmodel',
		name: '2°C in 2100'
	},
	deg3: {
		id: '3degmodel',
		name: '3°C in 2100'
	},
	deg4: {
		id: '4degmodel',
		name: '4°C in 2100'
	},
	warming: {
		id: 'dt',
		name: 'Warming level'
	}
})

export const members = asLabelledId({
	min: {
		id: 'lowest',
		name: 'Minimum'
	},
	min2: {
		id: '2nd_low',
		name: '2nd lowest'
	},
	pc10: {
		id: '10th_perc',
		name: '10th percentile'
	},
	median: {
		id: 'median',
		name: 'Median'
	},
	pc90: {
		id: '90th_perc',
		name: '90th percentile'
	},
	max2: {
		id: '2nd_high',
		name: '2nd highest'
	},
	max: {
		id: 'highest',
		name: 'Maximum'
	},
	m1: {
		id: 'm1',
		name: 'Member 1'
	},
	m2: {
		id: 'm2',
		name: 'Member 2'
	},
	m3: {
		id: 'm3',
		name: 'Member 3'
	},
	m4: {
		id: 'm4',
		name: 'Member 4'
	},
	m5: {
		id: 'm5',
		name: 'Member 5'
	},
	m6: {
		id: 'm6',
		name: 'Member 6'
	},
	m7: {
		id: 'm7',
		name: 'Member 7'
	},
	m8: {
		id: 'm8',
		name: 'Member 8'
	},
	m9: {
		id: 'm9',
		name: 'Member 9'
	},
	m10: {
		id: 'm10',
		name: 'Member 10'
	},
	m11: {
		id: 'm11',
		name: 'Member 11'
	},
	m12: {
		id: 'm12',
		name: 'Member 12'
	},
	m13: {
		id: 'm13',
		name: 'Member 13'
	},
	m14: {
		id: 'm14',
		name: 'Member 14'
	},
	m15: {
		id: 'm15',
		name: 'Member 15'
	}
})

export const resolutions = asLabelledId({
	km12: {
		id: '12km',
		name: '12x12km'
	},
	lau: {
		id: 'lau1',
		name: 'Local Authority Areas'
	},
	nuts3: {
		id: 'nuts3',
		name: 'Counties/Districts'
	},
	nuts2: {
		id: 'nuts2',
		name: 'Counties'
	},
	lrf: {
		id: 'lrf',
		name: 'Local Resilience Forums'
	},
	region: {
		id: 'admin',
		name: 'Region'
	},
	nation: {
		id: 'national',
		name: 'Nation'
	}
})

export const timePeriods = asLabelledNumericId({
	t1: {
		id: 1996,
		name: '1981-2010'
	},
	t2: {
		id: 2006,
		name: '1991-2020'
	},
	t3: {
		id: 2016,
		name: '2001-2030'
	},
	t4: {
		id: 2026,
		name: '2011-2040'
	},
	t5: {
		id: 2036,
		name: '2021-2050'
	},
	t6: {
		id: 2046,
		name: '2031-2060'
	},
	t7: {
		id: 2056,
		name: '2041-2070'
	},
	t8: {
		id: 2066,
		name: '2051-2080'
	},
	t9: {
		id: 2076,
		name: '2061-2090'
	},
	t10: {
		id: 2086,
		name: '2071-2100'
	}
})

export const warmingPeriods = asLabelledNumericId({
	t1: {
		id: 0.61,
		name: '1981-2010 (0.6°C)'
	},
	t2: {
		id: 1.0,
		name: '1°C'
	},
	t3: {
		id: 1.5,
		name: '1.5°C'
	},
	t4: {
		id: 2.0,
		name: '2°C'
	},
	t5: {
		id: 2.5,
		name: '2.5°C'
	},
	t6: {
		id: 3.0,
		name: '3°C'
	},
	t7: {
		id: 3.5,
		name: '3.5°C'
	},
	t8: {
		id: 4.0,
		name: '4°C'
	}
})

export type CategoryType = keyof typeof categories
export type IndicatorType = keyof typeof indicators
export type VariantType = keyof typeof variants
export type MetricType = keyof typeof metrics
export type StrandType = keyof typeof strands
export type EmissionType = keyof typeof emissions
export type MemberType = keyof typeof members
export type ResolutionType = keyof typeof resolutions
export type SpatialUnitType = string
export type TimePeriodType = keyof typeof timePeriods

export const indicatorFilter: { [K in CategoryType]: IndicatorType[] } = {
	climate: ['meantemp', 'mintemp', 'maxtemp', 'recordtemp', 'rainfall'],
	temp: ['heatwave', 'heathealth', 'cold', 'hotdays', 'tropnights', 'wbgt'],
	heatcool: ['hdd', 'cdd'],
	transport: ['roadmelt', 'roadaccident', 'railtemp', 'railops'],
	agri: [
		'sgs',
		'gsl',
		'gdd',
		'cropduration',
		'fieldops',
		'accfrost',
		'frostdays',
		'milkyield',
		'wheatheat',
		'psmd',
		'fcdays',
		'spi',
		'spei'
	],
	fire: ['firedanger', 'firedha', 'fireffmc'],
	water: ['runoff', 'flood', 'lowflow', 'ssi12', 'ssi24', 'soilmoisture']
}

export const variantFilter: { [K in IndicatorType]: VariantType[] } = {
	meantemp: ['annual', 'winter', 'spring', 'summer', 'autumn'],
	mintemp: ['winter', 'spring', 'summer', 'autumn', 'annual'],
	maxtemp: ['winter', 'spring', 'summer', 'autumn', 'annual'],
	recordtemp: ['hotday', 'hotmonth', 'wetmonth'],
	rainfall: ['winter', 'spring', 'summer', 'autumn', 'annual'],
	heatwave: ['none'],
	heathealth: ['none'],
	cold: ['none'],
	hdd: ['d15'],
	cdd: ['d18', 'd22'],
	hotdays: ['none'],
	tropnights: ['none'],
	wbgt: ['none'],
	roadmelt: ['none'],
	roadaccident: ['none'],
	railtemp: ['gt21', 'gt24', 'gt26', 'gt30'],
	railops: ['adverse', 'extreme'],
	sgs: ['none'],
	gsl: ['none'],
	gdd: ['none'],
	cropduration: ['none'],
	fieldops: ['none'],
	accfrost: ['none'],
	frostdays: ['none'],
	milkyield: ['none'],
	wheatheat: ['none'],
	psmd: ['none'],
	fcdays: ['none'],
	spi: ['none'],
	spei: ['none'],
	firedanger: ['vhigh', 'exceptional'],
	firedha: ['yellow', 'amber'],
	fireffmc: ['none'],
	runoff: ['annual', 'winter', 'spring', 'summer', 'autumn'],
	flood: ['y2', 'y10', 'y30'],
	lowflow: ['y2', 'y10'],
	ssi12: ['severe', 'extreme'],
	ssi24: ['severe', 'extreme'],
	soilmoisture: ['winter', 'spring', 'summer', 'autumn']
}

export const metricFilter: { [K in IndicatorType]: MetricType[] } = {
	meantemp: ['deltatemp'],
	mintemp: ['deltatemp'],
	maxtemp: ['deltatemp'],
	// Warning - this is the exception.
	// Some record temperatures have days and some have months.
	// This is coded for specifically in the ControlPanel
	recordtemp: ['days', 'months'],
	rainfall: ['percent'],
	heatwave: ['events', 'duration', 'chance'],
	heathealth: ['events', 'duration', 'chance'],
	cold: ['events', 'duration', 'chance'],
	hdd: ['dd'],
	cdd: ['dd'],
	hotdays: ['days', 'chance'],
	tropnights: ['days', 'chance'],
	wbgt: ['days', 'chance'],
	roadmelt: ['days'],
	roadaccident: ['days'],
	railtemp: ['days'],
	railops: ['days'],
	sgs: ['date'],
	gsl: ['duration'],
	gdd: ['dd'],
	cropduration: ['duration'],
	fieldops: ['date'],
	accfrost: ['dd'],
	frostdays: ['days'],
	milkyield: ['days'],
	wheatheat: ['days', 'chance'],
	psmd: ['mm'],
	fcdays: ['days'],
	spi: ['prop'],
	spei: ['prop'],
	firedanger: ['days', 'wildfirechance'],
	firedha: ['days', 'wildfirechance'],
	fireffmc: ['days'],
	runoff: ['percent'],
	flood: ['percent'],
	lowflow: ['percent'],
	ssi12: ['prop'],
	ssi24: ['prop'],
	soilmoisture: ['percent']
}

export const strandFilter: { [K in EmissionType]: StrandType[] } = {
	rcp26: ['hadGem', 'prob'],
	rcp45: ['prob'],
	rcp60: ['prob'],
	rcp85: ['hadGem', 'cmip', 'regional', 'prob'],
	deg15: ['hadGem', 'cmip'],
	deg2: ['hadGem', 'cmip'],
	deg3: ['hadGem', 'cmip'],
	deg4: ['hadGem', 'cmip'],
	warming: ['hadGem', 'cmip']
}

export const memberFilter: { [K in StrandType]: MemberType[] } = {
	hadGem: [
		'min',
		'min2',
		'median',
		'max2',
		'max',
		'm1',
		'm2',
		'm3',
		'm4',
		'm5',
		'm6',
		'm7',
		'm8',
		'm9',
		'm10',
		'm11',
		'm12',
		'm13',
		'm14',
		'm15'
	],
	cmip: [
		'min',
		'min2',
		'median',
		'max2',
		'max',
		'm1',
		'm2',
		'm3',
		'm4',
		'm5',
		'm6',
		'm7',
		'm8',
		'm9',
		'm10',
		'm11',
		'm12'
	],
	prob: ['pc10', 'median', 'pc90'],
	regional: [
		'min',
		'min2',
		'median',
		'max2',
		'max',
		'm1',
		'm2',
		'm3',
		'm4',
		'm5',
		'm6',
		'm7',
		'm8',
		'm9',
		'm10',
		'm11',
		'm12'
	]
}

export const specialIndicators: IndicatorType[] = [
	'runoff',
	'flood',
	'lowflow',
	'ssi12',
	'ssi24',
	'soilmoisture'
]

export const specialValues: {
	resolutions: ResolutionType[]
	emissions: EmissionType[]
	strands: StrandType[]
} = {
	resolutions: ['region', 'nation'],
	emissions: ['warming', 'rcp85'],
	strands: ['hadGem', 'cmip', 'regional']
}

export const cmipWarmingMembers: MemberType[] = [
	'min',
	'min2',
	'median',
	'max2',
	'max',
	'm1',
	'm2',
	'm3',
	'm4',
	'm5'
]

export const warmingTimes: TimePeriodType[] = [
	't1',
	't2',
	't3',
	't4',
	't5',
	't6',
	't7',
	't8'
]

export interface Datum {
	year: number
	location: string
	median: number
	min?: number
	min2?: number
	max2?: number
	max?: number
	m1?: number
	m2?: number
	m3?: number
	m4?: number
	m5?: number
	m6?: number
	m7?: number
	m8?: number
	m9?: number
	m10?: number
	m11?: number
	m12?: number
	m13?: number
	m14?: number
	m15?: number
	pc10?: number
	pc90?: number
}

export interface Scenario {
	emission: EmissionType
	strand: StrandType
}

export const maxAdditional = 3

export interface State {
	category: CategoryType
	indicator: IndicatorType
	variant: VariantType
	metric: MetricType
	strand: StrandType
	emission: EmissionType
	member: MemberType
	resolution: ResolutionType
	spatialUnit: SpatialUnitType
	timePeriod: TimePeriodType
	// Data is mapped by location, with an map of year:Datum
	data: { [key: string]: { [key: number]: Datum } }
	compareData: { [key: string]: { [key: number]: Datum } }
	// Scale range is mapped by year, with range of data for all locations
	scales: { [key: number]: ScaleLinear<Number, Number> }
	loadingCount: number
	geoData: GeoJSON | null
	spatialNames: { name: string; val: string }[]
	addingAdditionalUnits: boolean
	additionalSpatialUnits: SpatialUnitType[]
	addingAdditionalScenarios: boolean
	additionalScenario: Scenario | null
	showWelcomePage: boolean
}

export const state: State = {
	category: 'climate',
	indicator: 'meantemp',
	variant: 'annual',
	metric: 'deltatemp',
	strand: 'hadGem',
	emission: 'rcp85',
	member: 'median',
	resolution: 'lrf',
	spatialUnit: '43',
	timePeriod: 't10',
	data: {},
	compareData: {},
	scales: {},
	loadingCount: 0,
	geoData: null,
	spatialNames: [],
	addingAdditionalUnits: false,
	additionalSpatialUnits: [],
	addingAdditionalScenarios: false,
	additionalScenario: null,
	showWelcomePage: true
}
