<template>
	<div class="footer">
		<div class="org">
			<a
				href="https://www.ukclimateresilience.org/"
				target="_blank"
				title="The UK Climate Resilience Programme"
			>
				<img src="@/assets/img/crp-logo.svg" />
				<p>The UK Climate Resilience Programme</p>
			</a>
		</div>
		<div class="org">
			<a
				href="https://www.reading.ac.uk"
				target="_blank"
				title="The University of Reading"
			>
				<img src="@/assets/img/uor-logo.png" />
				<p>The University of Reading</p>
			</a>
		</div>
		<div class="org">
			<a
				href="https://www.ceh.ac.uk"
				target="_blank"
				title="UK Centre for Ecology & Hydrology"
			>
				<img src="@/assets/img/ukceh-logo.png" />
				<p>UK Centre for Ecology & Hydrology</p>
			</a>
		</div>
		<div class="org">
			<a
				href="https://the-iea.org"
				target="_blank"
				title="The Institute for Environmental Analytics"
			>
				<img src="@/assets/img/iea-logo.png" />
				<p>The Institute for Environmental Analytics</p>
			</a>
		</div>
	</div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'

@Component({
	name: 'Footer'
})
export default class Footer extends Vue {
	mounted() {}
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/climpolVars.scss';

.footer {
	display: flex;
	justify-content: space-evenly;
	background-color: $dark;

	.org {
		margin: 2px 0;

		a {
			display: flex;
			flex-direction: row;
			align-items: center;
			height: 100%;

			img {
				height: 100%;
				filter: drop-shadow(0 0 8px $mainBgColor);
			}

			p {
				margin-left: 12px;
				font-size: 0.8em;
				color: $tabColor;
			}

			&:hover {
				text-decoration: none;
				img {
					filter: drop-shadow(0 0 8px $mainBgColor)
						drop-shadow(0 0 16px $mainBgColor);
				}

				p {
					filter: drop-shadow(0 0 4px $mainBgColor);
				}
			}

		}
	}
}

@media (max-width: 950px) {
	.footer {
		.org {
			a {
				p {
					display: none;
				}
			}
		}
	}
}
</style>
